import React from "react";
import { SearchIcon } from "../../Icons";
import "./SearchBar.scss";

export default function SearchBar({ placeholder = "Search", onChange, value }) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };
  const handleClear = () => {
    onChange("");
  };

  return (
    <div className="flex  items-center w-full h-10 bg-white rounded-md px-1 gap-4 border-orange-800 border-2">
      <SearchIcon />
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        className="w-11/12 outline-none"
      />

      {value && (
        <div
          onClick={handleClear}
          className="p-1 bg-red-500 text-white rounded-full w-6 h-6 flex justify-center items-center shadow-md hover:scale-105 cursor-pointer"
        >
          X
        </div>
      )}
      {/* <SearchIcon  /> */}
    </div>
  );
}
