import React from "react";

export default function ApplicationStatus({ status, label, bordered }) {
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const containerStyles = {
    color: "black",
    display: "inline-block",
    padding: "0.5rem 1rem",

    borderRadius: bordered ? "5px" : "5px",
    backgroundColor:
      status === "approved" || status === "Paid"
        ? "#CCF0BE"
        : status === "rejected" || status === "Unpaid"
        ? "#F0CBBE"
        : "#EAEAEA",
    color:
      status === "approved" || status === "Paid"
        ? "#000000"
        : status === "disabled"
        ? "#000000"
        : "#fff",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyles}>
      <span style={{ color: "#494949" }}>{capitalizeFirstLetter(label)}</span>
    </div>
  );
}
