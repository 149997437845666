import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Input,
  List,
  Pagination,
  Skeleton,
  Space,
  Table,
  Tag,
} from "antd";
import "./VSPTable.scss";
import { useFetchVSPQuery, useUpdateSingleVSPMutation } from "./vspsApi";
import ApplicationStatus from "../../components/ui/ApplicationStatus/ApplicationStatus";
import FilterBy from "../../components/ui/FilterBy/FilterBy";
import SearchBar from "../../components/ui/SearchBar/SearchBar";
import TableDropdown from "../../components/ui/TableDropdown/TableDropdown";
import { renderTableCell } from "../../utils/ui";
import TableBtn from "../../components/ui/TableBtn/TableBtn";
import { CSVLink } from "react-csv";
import { IoCloudUploadOutline, IoCloudDownloadOutline } from "react-icons/io5";

const Column = (props) => {
  return <Table.Column {...props} />;
};

const tableOptions = [
  {
    label: "Enable",
    key: "enable",
    disabled: false,
  },
  {
    label: "Disable",
    key: "disable",
    disabled: false,
  },
  {
    label: "View",
    key: "view",
    disabled: false,
  },
];

const VSPTable = () => {
  const [statusFilter, setStatusFilter] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState(null);
  const [page, setPage] = useState(1);
  const [vspData, setVspData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/bcp/vsps`
        );
        const responseData = response.data.data || [];
        setVspData(responseData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const options = {};

  if (statusFilter && statusFilter !== "all") {
    options.isEnabled = statusFilter === "enabled";
  }
  if (searchQuery) {
    options.search = searchQuery;
  }
  if (page) {
    options.page = page;
  }
  console.log("options ==>", options);
  const { data, isFetching } = useFetchVSPQuery(options);
  const { items: vsps, total } = data || {};
  console.log("vsps", vsps);
  const handleFilterChange = (value) => {
    setStatusFilter(value);
  };

  const handleSearchUpdated = (value) => {
    setSearchQuery(value);
  };
  const handleDownloadCSV = () => {
    if (!vspData) {
      return <span>Loading...</span>;
    }

    if (vspData.length === 0) {
      return <span>No data available</span>;
    }

    const csvData = vspData.map((item) => ({
      "Vet ID": item.id || "-",
      "Vet Name": item.vetName || "-",
      "Phone Number": item.clinicNumber
        ? formatPhoneNumber(item.clinicNumber)
        : "-",
      Email: item.clinicEmail || "-",
      "Initial Training": item.trainingStage || "-",
      "Application Status": item.applicationStatus || "-",
      Status: item.isEnabled ? "Enabled" : "Disabled",
    }));

    const headers = [
      { label: "Vet ID", key: "Vet ID" },
      { label: "Vet Name", key: "Vet Name" },
      { label: "Phone Number", key: "Phone Number" },
      { label: "Email", key: "Email" },
      { label: "Initial Training", key: "Initial Training" },
      { label: "Application Status", key: "Application Status" },
      { label: "Status", key: "Status" },
    ];

    const csvReport = {
      data: csvData,
      headers: headers,
      filename: "VSP_Table.csv",
    };

    return (
      <CSVLink {...csvReport}>
        {" "}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "1rem",
            backgroundColor: "#E68F00",
            borderRadius: "10px",
            color: "black",
            fontWeight: "600",
          }}
        >
          <IoCloudDownloadOutline style={{ marginRight: "10px" }} /> Save as CSV
        </div>
      </CSVLink>
    );
  };

  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };

  const [updateSingleVSP] = useUpdateSingleVSPMutation();

  const handleClick = async (e, id) => {
    console.log("handleClick ===> ", id);
    if (e === "view") {
      window.location.href = `/vsp-management/requests/${id}`;
    } else {
      await updateSingleVSP({ id, isEnabled: e === "enable" }).unwrap();
    }
  };
  const handleClickDownload = async (e, id) => {
    console.log("Scheduled ID:", id);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_SERVER}/bcp/vsps/get-latest-training-doc/${id}`,
        {
          responseType: "blob", // Set responseType to 'blob' for downloading files
        }
      );

      // Create a blob URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "latest_training_doc.pdf"); // Set the name for the downloaded file
      document.body.appendChild(link);

      // Programmatically click the link to initiate the download
      link.click();

      // Remove the link from the DOM after the download is complete
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="filter-section mb-5">
        <div className="filter-component ">
          <div className="single-filter" style={{ width: "250px" }}>
            <FilterBy
              placeholder={"Application Status"}
              options={[
                { label: "All", value: "all" },
                { label: "Enabled", value: "enabled" },
                { label: "Disabled", value: "disabled" },
              ]}
              onChange={handleFilterChange}
            />
          </div>
          <Space
            size={[20]}
            className="vsp-menu-actions-btn"
            style={{ marginLeft: "auto" }}
          >
            {handleDownloadCSV()}
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "1rem",
                backgroundColor: "#E68F00",
                borderRadius: "10px",
                color: "black",
                fontWeight: "600",
              }}
            >
              <IoCloudUploadOutline style={{ marginRight: "10px" }} /> Bulk
              Upload
            </div> */}

            <SearchBar
              placeholder="Search VSPs"
              value={searchQuery}
              onChange={handleSearchUpdated}
            />
          </Space>
        </div>
      </div>
      <Table
        dataSource={vsps}
        rowKey={"id"}
        onChange={(pagination) => setPage(pagination.current)}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 25,
          showSizeChanger: false,
          className: "tb-pagination",
          total: total,
        }}
        // rowClassName={"animate__animated animate__fadeIn"}
      >
        <Table.Column title="S No." dataIndex="id" key="id" />
        <Table.Column
          title="Vet ID"
          dataIndex="basicInfo.id"
          key="id"
          align="center"
        />
        <Table.Column
          title="Vet Name"
          dataIndex="firstName"
          key="name"
          align="center"
        />
        <Table.Column
          title="Phone Number"
          dataIndex="clinicNumber"
          key="clinicNumber"
          render={renderTableCell}
          align="center"
        />
        <Table.Column
          title="Email"
          dataIndex="email"
          key="email"
          align="center"
        />

        <Table.Column
          title="Application Status"
          dataIndex="applicationStatus"
          key="applicationStatus"
          align="center"
          render={(text, record) => (
            <Tag
              color={
                record.applicationStatus === "approved" ? "success" : "error"
              }
            >
              {text}
            </Tag>
          )}
        />

        <Table.Column
          title="Status"
          dataIndex="isEnabled"
          key="basicInfo.isEnabled"
          align="center"
          render={(text, record) => (
            <Tag color={record.isEnabled ? "success" : "error"}>
              {record.isEnabled ? "Enabled" : "Disabled"}
            </Tag>
          )}
        />
        <Table.Column
          title="Initial Training"
          dataIndex="trainingStage"
          key="trainingStage"
          className="text-capitalize"
          align="center"
          render={(text, record) => {
            let tagColor;
            let tagContent;

            switch (text) {
              case "pending":
                tagColor = "default";
                tagContent = "Pending";
                break;
              case "scheduled":
                tagColor = "success";
                tagContent = (
                  <span
                    className="flex gap-2 mt-1 font-bold cursor-pointer"
                    style={{ height: "25px" }}
                    onClick={(action) => handleClickDownload(action, record.id)}
                  >
                    Scheduled <IoCloudDownloadOutline className="text-xl " />
                  </span>
                );
                break;
              default:
                tagColor = "default";
                tagContent = text;
                break;
            }

            return <Tag color={tagColor}>{tagContent}</Tag>;
          }}
        />

        <Table.Column
          title="Action"
          dataIndex="action"
          key="action"
          render={(text, record) => {
            return (
              <TableDropdown
                items={tableOptions.map((option) => {
                  return {
                    ...option,
                    disabled:
                      option.key === "view"
                        ? false
                        : option.disabled ||
                          record.isEnabled === (option.key === "enable"),
                  };
                })}
                onClick={(action) => handleClick(action, record.id)}
              />
            );
          }}
        />
      </Table>
    </div>
  );
};

export default VSPTable;
