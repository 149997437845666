import { Select } from "antd";
import React from "react";
import "./FilterBy.scss";

export default function FilterBy({ options = [], placeholder, onChange }) {
  return (
    <div className="filter-by">
      <span className="filterby-label">Filter By:</span>
      <Select
        size="large"
        onChange={onChange}
        style={{ minWidth: "150px" }}
        placeholder={placeholder}
      >
        { options.map((option) => (
          <Select.Option value={option.value}>{option.label}</Select.Option>
        ))}
      </Select>
    </div>
  );
}
