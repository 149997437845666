import { createBrowserRouter, useNavigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import ChangePassword from "./components/auth/ForgetPasswordForm/ChangePassword";
import NutritionistPlan from "./pages/NutritionistPlan";
import PetOwners from "./pages/PetOwners";
import PetOwnersInvited from "./pages/PetOwnersInvited";
import PetVisits from "./pages/PetVisits";
import Signup from "./pages/Signup";
import WellnessPlan from "./pages/WellnessPlan";
import WellnessPlanBcp from "./pages/WellnessPlanBcp";
import ForgetPassword from "./pages/ForgetPassword";
import ThankYou from "./pages/ThankYou";
import VetProfile from "./pages/VetProfile";
import ClinicInfo from "./pages/ClinicInfo";
import VetPractice from "./pages/VetPractice";
import VetrinaryServiceProviders from "./pages/VetrinaryServiceProviders";
import BCPContent from "./pages/BCPContent";
import BCPNutritionists from "./pages/BCPNutritionists";
import PetOwnersDetail from "./pages/PetOwnersDetail";
import PetDeceased from "./pages/PetDeceased";
import BCPNutritionist from "./pages/BCPNutritionist";
import Home from "./pages/Home";
import VSPDashboard from "./pages/VSPDashboard";
import NotFound from "./pages/NotFound";
import OnboardingSignupForm from "./components/auth/SignupForm/OnboardingSignupForm";
import VSPOnboarding from "./pages/Onboarding";
import VSPRequest from "./pages/VSPRequests";
import VSPSingleRequest from "./pages/VSPSingleRequest";
import { useEffect } from "react";
import Agreement from "./Agreement";
import CancelPet from "./pages/CancelPet";

function Homer() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/dashboard");
  }, [navigate]);
  return <div></div>;
}

const commonRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Homer />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/forget-password",
    element: <ForgetPassword />,
  },
  {
    path: "/passwordresettoken/:token",
    element: <ChangePassword />,
  },
  {
    path: "/thank-you",
    element: <ThankYou />,
  },
  {
    path: "/vet-profile",
    element: <VetProfile />,
  },
  {
    path: "/vet-practice",
    element: <VetPractice />,
  },
  {
    path: "/clinic-info",
    element: <ClinicInfo />,
  },
  {
    path: "/pet-visits",
    element: <PetVisits />,
  },
  {
    path: "/pet-owners",
    element: <PetOwners />,
  },
  {
    path: "/pet-owners/:id",
    element: <PetOwnersDetail />,
  },
  {
    path: "/pet-deceased",
    element: <PetDeceased />,
  },
  {
    path: "/profile/:id",
    element: <VSPSingleRequest />,
  },
];

const bcpRoutes = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/vsp-management",
    element: <VetrinaryServiceProviders />,
  },
  {
    path: "/vsp-management/requests",
    element: <VSPRequest />,
  },
  {
    path: "/vsp-management/requests/:id",
    element: <VSPSingleRequest />,
  },
  {
    path: "/wellness-practice",
    element: <WellnessPlanBcp />,
  },
  {
    path: "/bcp-nutritionists",
    element: <BCPNutritionists />,
  },
  {
    path: "/content",
    element: <BCPContent />,
  },
  {
    path: "/bcp-nutritionist",
    element: <BCPNutritionist />,
  },
  {
    path: "/cancelled-pet",
    element: <CancelPet />,
  },
];

const vspRoutes = [
  {
    path: "/vsp/onboarding",
    element: <VSPOnboarding />,
  },
  {
    path: "/vsp/home",
    element: <VSPDashboard />,
  },
  {
    path: "/vsp/pet-visits",
    element: <PetVisits />,
  },
  {
    path: "/vsp/pet-owners",
    element: <PetOwners />,
  },
  {
    path: "/vsp/pet-owners/invited",
    element: <PetOwnersInvited />,
  },
  {
    path: "/vsp/wellness-practice",
    element: <WellnessPlan />,
  },
  {
    path: "/vsp/well-plan",
    element: <NutritionistPlan />,
  },
  {
    path: "/vsp/Agreement",
    element: <Agreement />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const router = createBrowserRouter(
  (() => {
    const platform = process.env.REACT_APP_PLATFORM;
    const routes = [...commonRoutes];
    if (platform === "vsp") {
      routes.push(...vspRoutes);
    } else if (platform === "bcp") {
      routes.push(...bcpRoutes);
    }
    return routes;
  })()
);

export default router;
