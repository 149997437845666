import React from "react";
import { Col, Row, Form, Input, Button, Divider, Checkbox, Select } from "antd";
import "./VetProfileForm.scss";
import { Link } from "react-router-dom";
const { Option } = Select;

const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select size="large">
      <Option value="86">+86</Option>
      <Option value="87">+87</Option>
    </Select>
  </Form.Item>
);

export default function VetProfileForm() {
  return (
    <div className="vet-profile-form onboarding-form">
      <div className="logo-wrapper">
        <img src="/logo-carson-bear.png" />
      </div>
      <Row justify="center" className="page-header-wrap">
        <Col span={10}>
          <h1>Howdy!</h1>
          <h2>Let's get to know you.</h2>
        </Col>
      </Row>
      <div className="form-wrapper">
        <Row justify="center">
          <Col span={10}>
            <div className="form">
              <Form layout="vertical">
                <div className="form-group">
                  <Form.Item label="Name" name="name">
                    <Input
                      size="large"
                      className="form-control"
                      placeholder="Enter your Name Here"
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item name="phone" label="Phone Number">
                    <Input
                      addonBefore={prefixSelector}
                      size="large"
                      className="form-control"
                      placeholder="Enter your Mobile Here"
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item label="Email" name="email">
                    <Input
                      size="large"
                      className="form-control"
                      placeholder="Enter your Email Here"
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item label="Years of practice" name="yearsOfPractice">
                    <Input
                      size="large"
                      className="form-control"
                      placeholder="Write here"
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item label="DVM License number" name="dvmLicenseNumber">
                    <Input
                      size="large"
                      className="form-control"
                      placeholder="Write here"
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item label="State issued" name="stateIssued">
                    <Input
                      size="large"
                      className="form-control"
                      placeholder="Write here"
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item label="Upload Credential" name="uploadCredential">
                    <Input
                      size="large"
                      className="form-control"
                      placeholder="Upload your credential here"
                    />
                  </Form.Item>
                </div>

                <div className="form-group">
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      className="login-btn"
                    >
                      Sign Up
                    </Button>
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      className="previous-btn button-section"
                    >
                      Previous
                    </Button>
                  </Form.Item>
                </div>
                <div className="form-group signup-section">
                  <p type="text">
                    Already have an account?
                    <Link to="/login" className="signup">
                      {" "}
                      Log In{" "}
                    </Link>
                  </p>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
