import React from "react";
import { Col, Row, Form, Input, Button, Divider, Checkbox } from "antd";
import "./ThankYouForm.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../utils/selectors";
import { useFetchUserQuery } from "../../../api/auth";
import { removeUserFromLocalStorage } from "../../../utils/localstorage";
import { logoutUser } from "../../../api/auth";
// import  from './authSlice';

export default function ThankYouForm({ name }) {
  const dispatch = useDispatch();
  const handleLogout = () => {};
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const { data } = useFetchUserQuery();
  const handleGoBack = () => {
    removeUserFromLocalStorage();

    dispatch(logoutUser());
    setTimeout(() => {
      navigate("/login");
    }, 100);
  };

  return (
    <div className="thank-you-form auth-form">
      <div className="logo-wrapper">
        <img src="/logo-carson-bear.png" />
      </div>
      <Row justify="center">
        <Col span={18}>
          <div className="thank-you-label">
            <h1>Thank You!</h1>
          </div>
          <div className="application-under-review-label">
            <h3>Dr. {user?.name}, thank you for the registration!</h3>
            <p>
              Your Application is under review. we will notify you once the
              review is completed
            </p>
          </div>
        </Col>
      </Row>
      <div className="form-wrapper">
        <Row justify="center">
          <Col span={10}>
            <div className="form">
              <Form layout="vertical">
                <div className="form-group button-section">
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="button"
                      className="back-btn"
                      onClick={() => handleGoBack()}
                    >
                      Continue
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
