import { Drawer } from "antd";
import React, { Children } from "react";
import "./SideDrawer.scss";

const CloseIcon = () => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4026 15.0176L30.1972 3.22249C30.3762 3.04959 30.519 2.84277 30.6172 2.61409C30.7154 2.38542 30.7671 2.13947 30.7693 1.8906C30.7715 1.64173 30.724 1.39492 30.6298 1.16458C30.5355 0.93423 30.3964 0.724959 30.2204 0.548974C30.0444 0.37299 29.8351 0.233816 29.6048 0.139573C29.3744 0.0453312 29.1276 -0.00209185 28.8788 7.07686e-05C28.6299 0.00223339 28.3839 0.0539388 28.1553 0.15217C27.9266 0.250401 27.7198 0.393191 27.5469 0.572207L15.7518 12.3668L3.9572 0.572207C3.60441 0.227169 3.12979 0.0351755 2.63633 0.0378931C2.14287 0.0406107 1.67039 0.23782 1.32143 0.586722C0.972464 0.935625 0.775172 1.40807 0.772367 1.90153C0.769562 2.39499 0.961471 2.86964 1.30645 3.22249L13.1015 15.0176L1.30645 26.8126C1.12922 26.986 0.988134 27.1927 0.891366 27.421C0.794599 27.6492 0.744069 27.8944 0.742704 28.1423C0.741339 28.3902 0.789165 28.6359 0.883413 28.8652C0.977661 29.0945 1.11646 29.3028 1.29177 29.4781C1.46708 29.6533 1.67542 29.7921 1.90472 29.8863C2.13403 29.9805 2.37975 30.0283 2.62765 30.0269C2.87555 30.0255 3.1207 29.9749 3.34892 29.8781C3.57714 29.7813 3.78389 29.6402 3.9572 29.4629L15.7518 17.6683L27.5469 29.4629C27.9128 29.8289 28.3927 30.0121 28.872 30.0121C29.3514 30.0121 29.8317 29.8289 30.1972 29.4629C30.5485 29.1114 30.7459 28.6348 30.7459 28.1378C30.7459 27.6408 30.5485 27.1641 30.1972 26.8126L18.4026 15.0176Z"
      fill="black"
    />
  </svg>
);

export default function SideDrawer({ open, title, onClose, children }) {
  return (
    <Drawer
      open={open}
      width={600}
      closable
      onClose={onClose}
      closeIcon={false}
      title={
        <div className="drawer-header">
          <h3>{title}</h3>
          {/* <span className="close-icon" onClick={onClose}><CloseIcon /></span> */}
        </div>
      }
      headerStyle={{
        padding: '10px',
        marginTop: '10px'
      }}
    >
      {children}
    </Drawer>
  );
}
