const validateRegex = {
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[a-zA-Z\d@$!#%*?&]{8,}$/,
  phoneNumber: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  name: /^[a-zA-Z\s]*$/,

  margin: /((\d+)((\.\d{1,2})?))$/,
  textField: /[^a-z0-9]/gi,
  numeric: /^[0-9]+(\.[0-9]+)?$/,
  rank: /^[0-9]+$/,
  barCode: /^(?![0-9]*$)[a-zA-Z0-9]+$/,

  email:
    /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
};

const validatePassword = (_, value) => {
  if (!value) {
    return Promise.reject("Please enter password");
  }

  if (!validateRegex.password.test(value)) {
    return Promise.reject(
      "Your password must have at least 8 characters, and include at least one lowercase & one uppercase letter, one number, and a special character from this set: @#$!%*?&"
    );
  }

  return Promise.resolve();
};
const validateConfirmPassword = (_, value, password) => {
  console.log(" validateConfirmPassword ===>", value, " => ", password);

  if (!value) {
    return Promise.reject("Please enter password");
  }

  if (value !== password) {
    return Promise.reject("Passwords do not match");
  }
  if (!validateRegex.password.test(value)) {
    return Promise.reject(
      "Your password must have at least 8 characters, and include at least one lowercase & one uppercase letter, one number, and a special character from this set: @#$!%*?&"
    );
  }

  return Promise.resolve();
};
const validatePhoneNo = (_, value) => {
  if (!value) {
    return Promise.reject("Please enter mobile number");
  }

  if (!validateRegex.phoneNumber.test(value)) {
    return Promise.reject("Please enter valid phone no");
  }

  return Promise.resolve();
};
const validateClinicPhoneNo = (_, value) => {
  if (!value) {
    return Promise.reject("Please enter main clinic phone number");
  }

  if (!validateRegex.phoneNumber.test(value)) {
    return Promise.reject("Please enter valid phone no");
  }

  return Promise.resolve();
};
const validateNumericValue = (_, value, errorMessage) => {
  if (!value) {
    return Promise.reject(errorMessage || "Please enter numeric value");
  }

  if (!validateRegex.numeric.test(value)) {
    return Promise.reject(errorMessage || "Only numeric value allow");
  }

  return Promise.resolve();
};

const validateName = (_, value, errorMessages) => {
  if (!value) {
    return Promise.reject(
      errorMessages && errorMessages.empty ? errorMessages.empty : ""
    );
  }

  if (value.length > 50) {
    // Adjust the maximum length as needed
    return Promise.reject(
      errorMessages && errorMessages.toolong
        ? errorMessages.toolong
        : "Name is too long. Please enter a shorter name."
    );
  }

  if (!/^[a-zA-Z0-9\s]*$/.test(value)) {
    return Promise.reject(
      errorMessages && errorMessages.invalidChars
        ? errorMessages.invalidChars
        : "Name cannot contain special characters."
    );
  }

  // Add more validation rules as needed

  return Promise.resolve();
};

export {
  validateRegex,
  validatePassword,
  validatePhoneNo,
  validateClinicPhoneNo,
  validateName,
  validateNumericValue,
  validateConfirmPassword,
};
