import { configureStore } from "@reduxjs/toolkit";
import { apiClient } from "./api/apiClient";
import authReducer from './api/auth';

export const store = configureStore({
  reducer: {
    [apiClient.reducerPath]: apiClient.reducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiClient.middleware),
});

export const { dispatch } = store;

export default store;
