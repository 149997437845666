import React, { useEffect } from "react";
import "./AuthLayout.scss";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { app_platform } from "../../../config";
import { useFetchUserQuery } from "../../../api/auth";
import { useFetchVetProfileQuery } from "../../../api/onboarding";

export default function AuthLayout({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = useSelector((state) => state.auth?.isAuthenticated);
  const { data: user, isLoading: isUserLoading } = useFetchUserQuery();
  const { data: vetProfile, isLoading: isVetLoading } =
    useFetchVetProfileQuery();

  useEffect(() => {
    if (isUserLoading) {
      return;
    }
    // app_platform === "bcp" && isLoggedIn && navigate("/dashboard");
    if (app_platform === "vsp") {
      console.log("auth-layout", {
        isLoggedIn,
        pathname: location.pathname,
        isUserLoading,
        user,
        isVetLoading,
        vetProfile,
      });
      if (!isLoggedIn && location.pathname === "/vsp/onboarding") {
        console.log("Navigating to signup");
        navigate("/signup");
      }
      if (
        isLoggedIn &&
        (location.pathname === "/" ||
          location.pathname === "/login" ||
          location.pathname === "/signup")
      ) {
        if (user?.stage === "onboarding") {
          navigate("/vsp/onboarding");
        } else if (user?.stage === "pending") {
          localStorage.setItem("vetName", user?.name);
          window.location.href = "/vsp/onboarding?data=true";
        } else if (user?.stage === "approved") {
          navigate("/vsp/home");
        }
      }
    }
    // If isLogged and profile is complete Redirect to Homepage
    // If isLoggedIn and profile not complete Redirect to /onboarding pages
    // If isLoggedIn and profile is pending redirect to thank-you
    // If not isLoggedIn and page is onboarding, redirect to login page
  }, [
    isLoggedIn,
    location,
    navigate,
    user,
    vetProfile,
    isUserLoading,
    isVetLoading,
  ]);

  return (
    <div className="auth-layout">
      <div
        className="bg-wrapper"
        style={{
          backgroundImage: `url('/images/auth-bg.png')`,
          backgroundPosition: "bottom",
        }}
      >
        {/* <img src='/images/auth-bg.png' /> */}
      </div>
      <div className="auth-main-wrapper">{children}</div>
    </div>
  );
}
