import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getTokenFromLocalStorage } from "../utils/localstorage";

export const apiClient = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_SERVER,
    headers: {
      "Content-Type": "application/json",
      "api-token": getTokenFromLocalStorage(),
    },
  }),
  endpoints: (builder) => ({}),
});
console.log(
  "process.env.REACT_APP_API_SERVER ===>",
  process.env.REACT_APP_API_SERVER
);

export const { usePrefetch } = apiClient;
