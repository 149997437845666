import { Input, Space, Form, Row, Col, Radio } from "antd";
import React, { useState } from "react";
import "./PetDeceasedForm.scss";
import TableBtn from "../../../components/ui/TableBtn/TableBtn";
import {
  useGetPetOwnerQuery,
  useUpdatePetProfileMutation,
} from "../../pet-owners/petOwnersApi";
// import { useAddVideoMutation } from "../bcpContentApi";

export default function PetDeceasedForm({ data, onCancel }) {
  const [updatePetProfile] = useUpdatePetProfileMutation();

  const handleFormSubmit = (values) => {
    
    updatePetProfile({
      id: data.id,
      isDeceased: true,
    });
    onCancel(true);
  };

  const handleClick = (e) => {
   
 
    onCancel(true);
  };
  return (
    <Form
      className="pet-deceased-form"
      layout="vertical"
      onFinish={handleFormSubmit}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            className="form-section"
            name="deceasedReasonText"
            label="Select reason of pet’s death"
            size="large"
          >
            <Input size="large" style={{ minWidth: "150px" }} />
          </Form.Item>
          <Form.Item
            className="form-section"
            name="deceasedReasonSelect"
            label=""
            size="large"
          >
            <Radio.Group className="radio-button">
              <Space direction="vertical">
                <Radio value={"Medical Condition"}>Medical Condition</Radio>
                <Radio value={"Accident"}>Accident</Radio>
                <Radio value={"Old Age"}>Old Age</Radio>
                <Radio value={"Other"}>Other</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={16}>
                <Col span={24}>
                    <Form.Item className="mt-5">
                        <Input.TextArea rows={6} placeholder="write here" />
                    </Form.Item>
                </Col>
            </Row> */}
      <Row justify="end">
        <div className="button-section">
          <Space align="end" className="mt-5">
            <TableBtn
              theme={"sucess"}
              label={"Cancel"}
              onClick={handleClick}
              htmlType={"button"}
            />

            <TableBtn theme={"primary"} label={"Submit"} htmlType={"submit"} />
          </Space>
        </div>
      </Row>
    </Form>
  );
}
