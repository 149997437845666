import React from 'react'
import './NutritionistPlanTable.scss'
import {
    Form,
    Input,
    Button,
    Radio,
    Select,
    Table, Divider, Tag
} from 'antd';
const { Column, ColumnGroup } = Table;

export default function NutritionistPlanTable() {
    return (
        <div className='nutritionist-plan-table'>
            <div className='form-wrapper'>
                <div className='top-section'>
                    <Form>
                        <Form.Item label="Filter By" className='filter-section'>
                            <Select placeholder="Status(Mixed)">
                                <Select.Option value="demo" >Demo</Select.Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </div>

            </div>
            <Table>
                <Column title="Tail No." dataIndex="tail.no" key="tail.no" />
                <Column title="Pet Name" dataIndex="petName" key="petName" />
                <Column title="Nutritionist Name" dataIndex="nutritionistName" key="nutritionistName" />
                <Column title="Review Plans" dataIndex="reviewPlans" key="reviewPlans" />
                <Column title="Status" dataIndex="status" key="status" />
                <Column title="Action" dataIndex="action" key="action" />
            </Table>
        </div>

    )
}
