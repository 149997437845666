import React, { useEffect } from "react";
import AppLayout from "../components/layouts/AppLayout/AppLayout";
import PetOwnersTable from "../features/pet-owners/PetOwnersTable";

export default function PetOwners() {
  return (
    <AppLayout title={"Pet Owner Management"}>
      <PetOwnersTable />
    </AppLayout>
  );
}
