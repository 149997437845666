import { apiClient } from "../../api/apiClient";

const baseUrl = "/products/videos";

export const contentApi = apiClient.injectEndpoints({
  endpoints: (builder) => ({
    addVideo: builder.mutation({
      query: (video) => ({
        url: baseUrl,
        method: "POST",
        body: video,
      }),
      invalidatesTags: ["VideoContent"],
    }),
    getVideos: builder.query({
      query:  (params) => ({
        url: baseUrl + '/list',
        params,
      }),
      transformResponse: response => {
        return {
          total: response.total,
          items: response.data
        }
      },
      providesTags: ["VideoContent"],
    }),
    getSingleVideo: builder.query({
      query: (id) => `${baseUrl}/${id}`,
    }),
    updateVideo: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `${baseUrl}/${id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["VideoContent"],
    }),
  }),
});

export const { useGetVideosQuery, useGetSingleVideoQuery, useUpdateVideoMutation, useAddVideoMutation } = contentApi;
