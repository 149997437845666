import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Lottie from "react-lottie";
import { Col, Row, Form, Input, Button, message } from "antd";
import animationData from "../../../assets/images/dog.json";
import { Link, useHistory, useNavigate, useParams } from "react-router-dom";
import "./ChangePassword.css";

import axios from "axios";
import useAppPlatform from "../../../hooks/useAppPlatform";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { token } = useParams();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const platform = useAppPlatform({ vsp: "vsp", bcp: "bcp" });
  console.log("platform", platform);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const BCP = "/bcp/reset-password";
    const VSP = "/vsp/reset-password";

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_SERVER}${platform === "bcp" ? BCP : VSP}`,
        {
          passwordResetToken: token.toUpperCase(),
          password,
        }
      );

      message.success("Password changed successfully");
      localStorage.removeItem("passwordResetToken");
      navigate("/login");
    } catch (error) {
      message.error("Error changing password:", error.message);
    }
  };

  return (
    <div className="flex h-screen">
      <div className="leftSide">
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>

      <div className="rightSide">
        <form onSubmit={handleSubmit} className="formContainer">
          <h2 className="heading">Change Password</h2>
          {error && <p className="error">{error}</p>}
          <div>
            <label htmlFor="password" className="label">
              New Password
            </label>
            <div className="inputWithIcon">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                className="input"
                value={password}
                placeholder="Set a new password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </span>
            </div>
          </div>
          <div>
            <label htmlFor="confirmPassword" className="label">
              Confirm Password
            </label>
            <div className="inputWithIcon">
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                className="input"
                value={confirmPassword}
                placeholder="Confirm new password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <span
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <AiOutlineEye />
                ) : (
                  <AiOutlineEyeInvisible />
                )}
              </span>
            </div>
          </div>
          <button type="submit" className="button">
            Change Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
