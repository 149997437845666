import React from 'react'
import AuthLayout from '../components/layouts/AuthLayout/AuthLayout'
import VetProfileForm from '../components/onboarding/VetProfileForm/VetProfileForm'

export default function VetProfile() {
  return (
    <AuthLayout>
      <VetProfileForm />
    </AuthLayout>
  )
}
