import React from "react";
import AppLayout from "../components/layouts/AppLayout/AppLayout";
import WellPlanTable from "../components/tables/WellnessPlanTable/WellnessPlanTable";
import BCPNutritionistTable from "../features/bcp-nutritionists/BCPNutritionistTable";
import CancelledSubscription from "../features/cancelled-subscription/CancelledSubscription";

const CancelPet = () => {
  return (
    <AppLayout title={"Cancelled subscription"}>
      <CancelledSubscription />
    </AppLayout>
  );
};

export default CancelPet;
