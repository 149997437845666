import { Table } from "antd";
import React from "react";
import SearchBar from "../../components/ui/SearchBar/SearchBar";
import FilterBy from "../../components/ui/FilterBy/FilterBy";
import ApplicationStatus from "../../components/ui/ApplicationStatus/ApplicationStatus";

const Column = Table.Column;

export default function BCPNutritionistTable() {
  const [searchQuery, setSearchQuery] = React.useState(null);
  const handleSearchUpdated = (value) => {
    setSearchQuery(value);
  };
  return (
    <div className="page-wrapper">
      <div className="filter-section mb-5">
        <div className="d-block d-md-flex justify-content-between align-items-center">
          <div className="single-filter mb-4 mb-md-0">
            <FilterBy
              placeholder={"Status"}
              options={[
                { label: "Enabled", value: "enabled" },
                { label: "Disabled", value: "disabled" },
              ]}
            />
          </div>
          <SearchBar
            placeholder="Search Nutritionists"
            value={searchQuery}
            onChange={handleSearchUpdated}
          />
        </div>
      </div>
      <Table
        dataSource={[]}
        rowKey={"id"}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 20,
          showSizeChanger: false,
          className: "tb-pagination",
        }}
      >
        <Column title="S No." dataIndex="id" key="id" />
        <Column title="Name" dataIndex="name" key="name" />
        <Column title="Phone Number" dataIndex="phone" key="phone" />
        <Column title="Email" dataIndex="email" key="email" />
        <Table.Column
          title="Status"
          dataIndex="status"
          key="status"
          render={(text, record) => {
            return (
              <ApplicationStatus
                status={record.isActive ? "success" : "failed"}
                label={record.isActive ? "Joined" : "Invited"}
              />
            );
          }}
        />
        <Column title="Action" dataIndex="action" key="action" />
      </Table>
    </div>
  );
}
