import {
  Button,
  Input,
  Layout,
  Modal,
  message,
  Drawer,
  Avatar,
  List,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  Link,
  Route,
  RouterProvider,
  Routes,
  useNavigate,
} from "react-router-dom";
import { FaCircleInfo } from "react-icons/fa6";
import { UserOutlined } from "@ant-design/icons";
import { DropdownArrow, VetIcon, UserIcon } from "../../Icons";
import SideNav from "../../SideNav/SideNav";
import "./AppLayout.scss";
import { useSelector, useDispatch } from "react-redux";
import router from "../../../";
import Dashboard from "../../../pages/Dashboard";
import { logoutUser, useFetchUserQuery } from "../../../api/auth";
import {
  getUserFromLocalStorage,
  removeUserFromLocalStorage,
} from "../../../utils/localstorage";
import { selectIsLoggedIn } from "../../../utils/selectors";
import useAppPlatform from "../../../hooks/useAppPlatform";
import { useFetchVetProfileQuery } from "../../../api/onboarding";
import { FaBarsStaggered } from "react-icons/fa6";
import { HiMiniBars3BottomLeft } from "react-icons/hi2";
import { HiMiniBars3BottomRight } from "react-icons/hi2";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useInvitationCount } from "../../../pages/dashboard-api/useInvitationCount";
import { usePatientJoinedCount } from "../../../pages/dashboard-api/useInvitationCount";
// import { useInvitationCount, usePatientJoinedCount } from "./VSPDashboardOne";

const UserLoggedInDropdown = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dropdown, setDropdown] = useState(false);
  const [userName, setUserName] = useState("");
  const isLoggedIn = useSelector((state) => state.auth?.isAuthenticated);
  // const name = useSelector((state) => state.auth?.user?.name);

  const logout = () => {
    removeUserFromLocalStorage();

    dispatch(logoutUser());
    setTimeout(() => {
      navigate("/login");
      window.location.reload(false);
    }, 100);
  };

  const platform = useAppPlatform({ vsp: "vsp", bcp: "bcp" });

  const handleGetUserName = () => {
    const userDetail = getUserFromLocalStorage();
    if (platform === "vsp") {
      if (userDetail?.stage !== "approved") {
        alert(`Your account verifications is ${userDetail?.stage}.`);
        logout();
      }
      if (userDetail?.isEnabled !== true) {
        alert(`Your account is Disabled at the moment. Try again later.`);
        logout();
      }
    }
    setUserName(userDetail?.name);
  };

  useEffect(() => {
    if (isLoggedIn) {
      handleGetUserName();
    }
  }, [isLoggedIn]);

  const { data: vetProfile, isLoading: isVetLoading } =
    useFetchVetProfileQuery();
  const id = vetProfile?.id;

  return (
    <div className="user-logged-in-dropdown-wrapper">
      <div
        className="logged-in-user-dropdown"
        onClick={() => setDropdown(!dropdown)}
      >
        <span className="vet-avatar">
          <UserIcon />
        </span>
        <span className="vet-name">{userName}</span>
        <span className="dropdown-icon">
          <DropdownArrow />
        </span>
      </div>
      {dropdown && (
        <div className="dropdown-wrapper" style={{ width: "100%" }}>
          {platform === "vsp" && (
            <ul>
              <li
                style={{ textAlign: "center", fontSize: "1.2rem" }}
                onClick={() => navigate(`/profile/${id}?viewOnly=true`)}
              >
                My Profile
              </li>
            </ul>
          )}
          <ul>
            <li
              onClick={logout}
              style={{ textAlign: "center", fontSize: "1.2rem" }}
            >
              Logout
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default function AppLayout({
  title,
  children,
  layoutActions,
  invitationCount,
}) {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const navigate = useNavigate();
  const { data, isError, isLoading } = useFetchUserQuery();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      window.location.href = "/login";
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (data) {
      if (data.stage === "onboarding") {
        navigate("/vsp/onboarding");
      }
      // Perform navigation using navigate function
    }
  }, [data, navigate]);

  console.log("window", window.innerWidth);

  const windowWidth = window.innerWidth;
  const isMobile = windowWidth <= 576;

  const getWidth = () => {
    if (!isMobile) {
      return 280;
    }
    if (showMobileMenu) {
      return 280;
    }
    return 0;
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputValues, setInputValues] = useState([{ email: "", name: "" }]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    // Validate input values
    for (const inputValue of inputValues) {
      if (!inputValue.email || !inputValue.name) {
        message.error("Please fill in both the email and name of the nominee.");
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(inputValue.email)) {
        message.error("Please enter a valid email address.");
        return;
      }
    }

    // Construct payload
    const payload = {
      petOwnerDetails: inputValues,
    };

    const accessToken = localStorage.getItem("BCP_VSP_TOKEN");
    const baseURL = process.env.REACT_APP_API_SERVER;
    const apiUrl = `${baseURL}/vsp/invite-pet-owner`;

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Token": accessToken,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((result) => {
        if (
          result.data &&
          result.data.alreadyJoinedEmails &&
          result.data.alreadyJoinedEmails.length > 0
        ) {
          // Show a modal with already joined emails
          Modal.info({
            title: "Already Joined",
            centered: true,
            icon: (
              <FaCircleInfo
                style={{
                  color: "#e68f00",
                  fontSize: "25px",
                  marginRight: "10px",
                }}
              />
            ),
            content: (
              <>
                <p
                  style={{
                    fontSize: "1rem",
                    fontStyle: "italic",
                    marginBottom: "10px",
                  }}
                >
                  The following users are already joined:
                </p>
                <List
                  size="xl"
                  bordered
                  dataSource={result.data.alreadyJoinedEmails}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
              </>
            ),
            okText: "OK",
            okButtonProps: {
              type: "primary",
              style: { backgroundColor: "#e68f00" },
            }, // Change button color
            onOk() {
              setInputValues([{ email: "", name: "" }]); // Clear all form fields
              setIsModalVisible(false);
            },
          });
          const totalSentUsers =
            inputValues.length - result.data.alreadyJoinedEmails.length;
          if (totalSentUsers > 0) {
            message.success(
              `Invite sent successfully to ${totalSentUsers} users`
            );
          }
        } else if (result.statusCode === 200) {
          // If success, show success message

          message.success("Invite sent successfully!");

          setInputValues([{ email: "", name: "" }]); // Clear all form fields
          localStorage.setItem("callCount", true);
        } else {
          // If API call fails, show error message
          message.error("An error occurred while sending the invitation.");
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
        message.error("An error occurred while sending the invitation.");
      });

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const addField = () => {
    setInputValues([...inputValues, { email: "", name: "" }]);
  };
  const removeField = (indexToRemove) => {
    setInputValues(inputValues.filter((_, index) => index !== indexToRemove));
  };
  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedValues = [...inputValues];
    updatedValues[index][name] = value;
    setInputValues(updatedValues);
  };
  const platform = useAppPlatform({ vsp: "vsp", bcp: "bcp" });

  return (
    <>
      <div className="app-layout">
        <Layout hasSider={true}>
          <Layout.Sider theme="light" width={getWidth()}>
            <div className="sidebar-wrapper">
              <div className="logo-wrapper">
                <img src="/logo-carson-bear.png" />
                <div
                  className="mobile-show-hide-btn"
                  onClick={() => setShowMobileMenu(!showMobileMenu)}
                >
                  <HiMiniBars3BottomLeft />
                </div>
              </div>
              <div className="navigation-wrapper">
                <SideNav />
              </div>
            </div>
          </Layout.Sider>
          <Layout.Content className="layout-content-wrapper">
            <div className="page-header">
              <div
                className="mobile-show-btn"
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              >
                <HiMiniBars3BottomRight />
              </div>
              <h1 className="animate__animated animate__fadeIn">{title}</h1>
              <div className="right-bar">
                {(title === "Dashboard" || title === "Pet Owner Management") &&
                  platform === "vsp" && (
                    <button
                      type="primary"
                      size="large"
                      className=" p-2 w-40 bg-amber-500  rounded-lg hover:bg-amber-400 "
                      onClick={showModal}
                    >
                      Invite a Pet Owner
                    </button>
                  )}
                <div className="layout-action-wrapper ms-auto">
                  {layoutActions}
                </div>
                <UserLoggedInDropdown />
              </div>
            </div>
            <div className="page-content animate__animated animate__fadeIn">
              {children}
            </div>
          </Layout.Content>
        </Layout>
      </div>
      <Drawer
        title="Please fill the details of the Nominee"
        visible={isModalVisible}
        onOk={handleOk}
        width={600}
        onClose={handleCancel}
        footer={[
          <button
            key="cancel"
            onClick={handleCancel}
            className=" rounded-md bg-gray-100 px-3 py-2"
          >
            Cancel
          </button>,
          <button
            key="invite"
            className=" rounded-md bg-amber-500 px-3 py-2"
            onClick={handleOk}
            style={{ marginLeft: "20px" }}
          >
            Invite
          </button>,
        ]}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            padding: "0.5rem",
            backgroundColor: "#e68f00",
            width: 300,
            borderRadius: 5,
            margin: "0 auto",
            fontWeight: "600",
            marginBottom: "30px",
          }}
        >
          <Avatar size={34} icon={<UserOutlined />} />
          <span style={{ marginLeft: 10 }}>Pet Owner's Details</span>
        </div>
        {inputValues.map((inputValue, index) => (
          <div key={index}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {index > 0 && (
                <AiOutlineCloseCircle
                  onClick={() => removeField(index)}
                  style={{
                    marginLeft: "auto",
                    cursor: "pointer",
                    fontSize: "30px",
                    color: "red",
                  }}
                />
              )}
              <label
                htmlFor={`nomineeEmail_${index}`}
                style={{ marginBottom: 10, fontWeight: "bold" }}
              >
                Email:
              </label>
              <Input
                id={`nomineeEmail_${index}`}
                name="email"
                placeholder="Enter email of nominee"
                value={inputValue.email}
                onChange={(e) => handleInputChange(index, e)}
                type="email"
                style={{ width: 550, height: 40, backgroundColor: "#F9F9F9" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 30,
              }}
            >
              <label
                htmlFor={`nomineeName_${index}`}
                style={{ marginBottom: 10, fontWeight: "bold" }}
              >
                Name:
              </label>
              <Input
                id={`nomineeName_${index}`}
                name="name"
                placeholder="Enter name of nominee"
                value={inputValue.name}
                onChange={(e) => handleInputChange(index, e)}
                style={{ width: 550, height: 40, backgroundColor: "#F9F9F9" }}
              />
            </div>
            <div style={{ marginTop: "30px" }}>
              <hr />
            </div>
          </div>
        ))}
        <Button
          onClick={addField}
          style={{ marginTop: 20, backgroundColor: "green", color: "white" }}
        >
          + Add more
        </Button>
      </Drawer>
    </>
  );
}
