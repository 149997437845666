import React, { useState } from "react";
import axios from "axios";
import { Input, Row, Col, Form, Radio, Image, message, Spin } from "antd";
import "./PetOwnerDetailForm.scss";
import TableBtn from "../../../components/ui/TableBtn/TableBtn";
import { useNavigate } from "react-router-dom";

export default function PetOwnerDetailForm({ pet, onCancel, onDeceasedClick }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [shipmentType, setShipmentType] = useState(pet.shipmentType);

  function convertMonthsToYearsAndMonths(months) {
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
    return { years, remainingMonths };
  }
  console.log(pet);
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleShipmentTypeChange = async (e) => {
    const newShipmentType = e.target.value;
    const token = localStorage.getItem("BCP_VSP_TOKEN"); // Retrieve the token from local storage
    setLoading(true);

    try {
      await axios.put(
        `https://api.carsonandbearpets.com/bcp/pets/${pet.id}`,
        {
          shipmentType: newShipmentType,
        },
        {
          headers: {
            "Access-Token": token,
          },
        }
      );

      setShipmentType(newShipmentType);
      message.success("Shipment type updated successfully");

      // Redirect to /pet-owners after success
      window.location.reload();
    } catch (error) {
      message.error("Failed to update shipment type");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Form className="pet-owner-detail-form" layout="vertical">
        <Row justify="center">
          <Image
            width={150}
            height={150}
            style={{ objectFit: "cover" }}
            src={
              pet?.profilePicURL ||
              "https://posfacturar.com/pos_organicnails/public/upload/default_image/default_pet.jpg"
            }
          />
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="name" label="Pet name" size="large">
              <Input
                size="large"
                style={{ minWidth: "150px" }}
                placeholder="Jordan"
                defaultValue={pet.petName}
                readOnly
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="breed" label="Breed" size="large">
              <Input
                size="large"
                style={{ minWidth: "150px" }}
                placeholder="breed"
                defaultValue={pet.breed}
                readOnly
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="weight" label="Weight">
              <Input
                size="large"
                style={{ minWidth: "150px" }}
                placeholder="9"
                defaultValue={pet.weight}
                readOnly
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="age" label="Age">
                  <Input
                    size="large"
                    style={{ minWidth: "80px" }}
                    placeholder="years"
                    defaultValue={`${
                      convertMonthsToYearsAndMonths(pet.age).years
                    } Years`}
                    readOnly
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="type" label=" ">
                  <Input
                    size="large"
                    style={{ minWidth: "80px" }}
                    placeholder=" Months"
                    defaultValue={`${
                      convertMonthsToYearsAndMonths(pet.age).remainingMonths
                    } Months`}
                    readOnly
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Col span={24}>
          <Form.Item name="shipmentType" label="Shipment Type">
            <Radio.Group
              defaultValue={shipmentType}
              onChange={handleShipmentTypeChange}
            >
              <Radio value="monthly">Monthly</Radio>
              <Radio value="quarterly">Quarterly</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="address" label="Address">
              <Input
                size="large"
                style={{ minWidth: "150px" }}
                placeholder="Address"
                defaultValue={pet.Address.address}
                readOnly
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="city" label="City">
              <Input
                size="large"
                style={{ minWidth: "150px" }}
                placeholder="Address"
                defaultValue={pet.Address.city}
                readOnly
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="state" label="State">
              <Input
                size="large"
                style={{ minWidth: "150px" }}
                placeholder="State"
                defaultValue={pet.Address.state}
                readOnly
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="zipcode" label="Zipcode">
              <Input
                size="large"
                style={{ minWidth: "150px" }}
                placeholder="zipcode"
                defaultValue={pet.Address.zipcode}
                readOnly
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="medicalNotes" label="Medical notes">
              <Input.TextArea
                rows={6}
                placeholder="please enter url Medical notes"
                readOnly
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="report" label="Report" size="large">
              <Input size="large" style={{ minWidth: "150px" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <div className="form-group button-section">
            {pet.isDeceased ? (
              ""
            ) : (
              <TableBtn
                theme={"primary"}
                label={"Mark this pet as deceased"}
                onClick={onDeceasedClick}
              />
            )}
          </div>
        </Row>
      </Form>
    </Spin>
  );
}
