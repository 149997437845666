import React from "react";
import AppLayout from "../components/layouts/AppLayout/AppLayout";
import BCPContentTable from "../features/content/BCPContentTable";
import TableBtn from "../components/ui/TableBtn/TableBtn";
import { Button, Drawer, Form, Input, Upload } from "antd";
import { UploadVideoIcon } from "../components/Icons";
import SideDrawer from "../components/ui/SideDrawer/SideDrawer";
import ContentAddForm from "../features/content/ContentAddForm/ContentAddForm";

export default function BCPContent() {
  const [showAddDrawer, setShowAddDrawer] = React.useState(false);
  const submitVideo = (values) => {
    console.log(values);
  };

  const handleClose = () => {
    setShowAddDrawer(false);
  };

  return (
    <>
      <AppLayout title={"BCP Content"}>
        <div style={{ width: "100%", fontSize: "3rem", textAlign: "center" }}>
          <h1>Coming Soon ⌛</h1>
        </div>
      </AppLayout>
    </>
  );

  return (
    <AppLayout
      title={"BCP Content"}
      layoutActions={
        <TableBtn
          label={"Upload Videos"}
          icon={<UploadVideoIcon />}
          onClick={() => setShowAddDrawer(true)}
        />
      }
    >
      <BCPContentTable />
      <SideDrawer open={showAddDrawer} onClose={() => setShowAddDrawer(false)}>
        <ContentAddForm onCancel={handleClose} />
      </SideDrawer>
    </AppLayout>
  );
}
