import React from 'react'
import AuthLayout from '../components/layouts/AuthLayout/AuthLayout'
import ThankYouForm from '../components/auth/ThankYouForm/ThankYouForm'

export default function ThankYou() {
    return (
        <AuthLayout>
            <ThankYouForm />
        </AuthLayout>
    )
}
