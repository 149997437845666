import { useState, useEffect } from "react";
import axios from "axios";
import { getTokenFromLocalStorage } from "../../utils/localstorage";

export const usePatientJoinedCount = () => {
  const [patientJoinedCount, setPatientJoinedCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/vsp/patient-joined-count`,
          {
            headers: {
              "Api-Token": getTokenFromLocalStorage(),
            },
          }
        );
        const { data, success, message } = response.data;
        if (success) {
          setPatientJoinedCount(data);
        } else {
          console.error("Error fetching patient joined count:", message);
        }
      } catch (error) {
        console.error("Error fetching patient joined count:", error);
      }
    };

    fetchData();
  }, []);

  return patientJoinedCount;
};
