const BCP_VSP_TOKEN = "BCP_VSP_TOKEN";
const BCP_VSP_USER = "BCP_VSP_USER";

export const saveUserToLocalStorage = (user, token) => {
  localStorage.setItem(BCP_VSP_USER, JSON.stringify(user));
  localStorage.setItem(BCP_VSP_TOKEN, token);
};

export const getUserFromLocalStorage = () => {
  const user = localStorage.getItem(BCP_VSP_USER);
  return user ? JSON.parse(user) : null;
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem(BCP_VSP_USER);
  localStorage.removeItem(BCP_VSP_TOKEN);
};

export const getTokenFromLocalStorage = () => {
  return localStorage.getItem(BCP_VSP_TOKEN);
};
