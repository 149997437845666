import React from "react";
// import { Table, Divider, Tag } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import petData from "./petTabledata";
import "./PetVisitsTable.scss";
import { CSVLink } from "react-csv";
import { IoCloudUploadOutline, IoCloudDownloadOutline } from "react-icons/io5";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Row,
  Col,
  Layout,
  Table,
  Divider,
  Tag,
  Space,
} from "antd";
import { PetActiveIcon } from "../../Icons";
import TableDropdown from "../../ui/TableDropdown/TableDropdown";
const { Column, ColumnGroup } = Table;

const tableOptions = [
  // {
  //   label: "Mark as Complete",
  //   key: "completed",
  // },
  // {
  //   label: "Mark Pet as Deceased",
  //   key: "deceased",
  // },
  {
    label: "View Details",
    key: "Details",
  },
  {
    label: "Cancel Appoinment",
    key: "Cencel",
  },
];

const LastAppointment = ({ text, status }) => {
  return (
    <p
      style={{
        backgroundColor: "rgba(0, 128, 0, 0.2)",
        padding: "5px 10px",
        margin: "5px 10px",
        textAlign: "center",
        color: "green",
        borderRadius: "5px",
      }}
    >
      {text}
    </p>
  );
};

const DeceasedStatus = ({ status }) => {
  return (
    <div style={{ textAlign: "center" }}>
      {" "}
      <PetActiveIcon />
    </div>
  );
};
const headers = [
  { label: "S.No", key: "s.no" },
  { label: "Pet Name", key: "petName" },
  { label: "Breed", key: "breed" },
  { label: "Pet Owner Name", key: "petOwnerName" },
  { label: "Mobile no", key: "mobile" },
  { label: "Last Appointment", key: "address" },
  { label: "Status", key: "petStatus" },
];
const csvReport = {
  data: petData,
  headers: headers,
  filename: "VSP_Table.csv",
};
export default function PetVisitsTable() {
  return (
    <div className="pet-visits-table">
      <div className="form-wrapper">
        <div className="top-section">
          <Form className="w-100">
            <Row gutter={16} className="w-100">
              <Col xs={24} md={6}>
                <Form.Item label="Filter By" className="mb-3 mb-md-0">
                  <Select
                    placeholder="Deceased status (Mixed)"
                    style={{ height: "50px" }}
                  />
                  <Select.Option value="demo">Demo</Select.Option>
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item label="Filter By" className="mb-3 mb-md-0">
                  <Select
                    placeholder="Appointment status (Mixed)"
                    style={{ height: "50px" }}
                  />
                  <Select.Option value="demo">Demo</Select.Option>
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Input
                  style={{ height: "50px" }}
                  prefix={<SearchOutlined />}
                  placeholder="Search"
                  className="w-100 mb-2"
                />
              </Col>
              <Col xs={24} md={6}>
                {/* <Button className="btn-icon">
                  <IoCloudDownloadOutline />
                  Save as CSV
                </Button> */}
                {/* <Button className="btn-icon">
                    <IoCloudUploadOutline />
                    Bulk Upload
                  </Button> */}
                <CSVLink {...csvReport}>
                  {" "}
                  <div
                    style={{ display: "flex" }}
                    className="bg-amber-500 p-3   md:mt-0 rounded-lg hover:bg:amber-400 hover:text-white"
                  >
                    <IoCloudDownloadOutline className="text-2xl mr-2" /> Save as
                    CSV
                  </div>
                </CSVLink>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <div className="table-wrapper">
        <Table dataSource={petData} pagination={false} scroll={{ x: true }}>
          <Table.Column title="S.No" dataIndex="s.no" key="s.no" />
          <Table.Column title="Pet name" dataIndex="petName" key="petName" />
          <Table.Column title="Breed" dataIndex="breed" key="breed" />
          <Table.Column
            title="Pet owner name"
            dataIndex="petOwnerName"
            key="address"
          />
          <Table.Column title="Mobile" dataIndex="mobile" key="mobile" />

          <Table.Column
            title="Last Appointment"
            dataIndex="address"
            key="petOwnerName"
            render={(text) => <Tag color="pink">{text}</Tag>}
          />
          {/* <Table.Column
            title="Deceased Status"
            dataIndex="deceasedStatus"
            key="deceasedStatus"
            render={(status) => {
              return <DeceasedStatus status={status} />;
            }}
          /> */}
          <Table.Column
            title="Status"
            dataIndex="petStatus"
            key="petStatus"
            render={(text) => (
              <Tag color={text === "active" ? "green" : "red"}>
                {text === "active" ? "Active" : "Inactive"}
              </Tag>
            )}
          />
          <Table.Column
            title="Action"
            dataIndex="action"
            key="action"
            render={(text, record) => {
              return (
                <TableDropdown
                  items={tableOptions.map((option) => option)}
                  onClick={() => console.log("lol")}
                />
              );
            }}
          />
        </Table>
      </div>
    </div>
  );
}
