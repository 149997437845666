import React from 'react'
import AuthLayout from '../components/layouts/AuthLayout/AuthLayout'
import VetPracticeForm from '../components/onboarding//VetPracticeForm/VetPracticeForm'

export default function VetPractice() {
    return (
        <AuthLayout>
            <VetPracticeForm />
        </AuthLayout>
    )
}
