import { apiClient } from "../../api/apiClient";

const TAG = "InvitedPetOwners";
const platform = process.env.REACT_APP_PLATFORM;
const baseUrl = `/${platform}/get-pet-owners-invited-data`;

export const invitedPetOwnersApi = apiClient.injectEndpoints({
  endpoints: (builder) => ({
    fetchInvitedPetOwners: builder.query({
      query: (params) => ({
        url: baseUrl,
        params,
      }),
      transformResponse: (response) => {
        return {
          total: response.total,
          items: response.data,
        };
      },
      providesTags: [TAG],
    }),
  }),
});

export const { useFetchInvitedPetOwnersQuery } = invitedPetOwnersApi;
