import { apiClient } from "../../api/apiClient";

const TAG = "PetOwners";
const platform = process.env.REACT_APP_PLATFORM;
const baseUrl = `/${platform}/pet-owners`;
// const baseUrl = "/bcp/pet-owners";

export const petOwnersApi = apiClient.injectEndpoints({
  endpoints: (builder) => ({
    fetchPetOwners: builder.query({
      query: (params) => ({
        url: baseUrl,
        params,
      }),
      transformResponse: (response) => {
        return {
          total: response.total,
          items: response.data,
        };
      },
      providesTags: [TAG],
    }),
    getPetOwner: builder.query({
      query: (id) => `${platform}/pet-owners/${id}`,
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: [TAG],
    }),
    updatePetOwner: builder.mutation({
      query: (user) => ({
        url: `${platform}/pet-owners/${user.id}`,
        method: "PUT",
        body: user,
      }),
      invalidatesTags: [TAG],
    }),
    updatePetProfile: builder.mutation({
      query: (data) => ({
        url: `${platform}/pets/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [TAG],
    }),
  }),
});

export const {
  useFetchPetOwnersQuery,
  useGetPetOwnerQuery,
  useUpdatePetOwnerMutation,
  useUpdatePetProfileMutation,
} = petOwnersApi;
