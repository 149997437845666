const petData = [
  {
    "s.no": 1,
    petName: "Fluffy",
    breed: "Golden Retriever",
    petOwnerName: "John Doe",
    mobile: "123-456-7890",
    address: "2023-01-01",
    deceasedStatus: "No",
    action: "View",
    petStatus: "active",
  },
  {
    "s.no": 2,
    petName: "Whiskers",
    breed: "Siamese",
    petOwnerName: "Jane Smith",
    mobile: "987-654-3210",
    address: "2023-02-15",
    deceasedStatus: "Yes",
    action: "Edit",
    petStatus: "InActive",
  },
  {
    "s.no": 3,
    petName: "Buddy",
    breed: "Labrador",
    petOwnerName: "Alice Johnson",
    mobile: "555-123-4567",
    address: "2023-03-10",
    deceasedStatus: "No",
    action: "Delete",
    petStatus: "active",
  },
  {
    "s.no": 4,
    petName: "Max",
    breed: "Beagle",
    petOwnerName: "Michael Williams",
    mobile: "111-222-3333",
    address: "2023-04-05",
    deceasedStatus: "No",
    action: "View",
    petStatus: "inActive",
  },
  {
    "s.no": 5,
    petName: "Luna",
    breed: "Husky",
    petOwnerName: "Sarah Brown",
    mobile: "444-555-6666",
    address: "2023-05-20",
    deceasedStatus: "No",
    action: "Edit",
    petStatus: "active",
  },
  {
    "s.no": 6,
    petName: "Charlie",
    breed: "Poodle",
    petOwnerName: "David Miller",
    mobile: "777-888-9999",
    address: "2023-06-15",
    deceasedStatus: "Yes",
    action: "Delete",
    petStatus: "inActive",
  },
  {
    "s.no": 7,
    petName: "Coco",
    breed: "Dachshund",
    petOwnerName: "Emma Wilson",
    mobile: "333-666-9999",
    address: "2023-07-10",
    deceasedStatus: "No",
    action: "View",
    petStatus: "active",
  },
  {
    "s.no": 8,
    petName: "Oscar",
    breed: "Bulldog",
    petOwnerName: "Olivia Davis",
    mobile: "888-777-6666",
    address: "2023-08-25",
    deceasedStatus: "No",
    action: "Edit",
    petStatus: "active",
  },
  {
    "s.no": 9,
    petName: "Molly",

    breed: "Ragdoll",
    petOwnerName: "Daniel Anderson",
    mobile: "555-444-3333",
    address: "2023-09-05",
    deceasedStatus: "No",
    action: "Delete",
    petStatus: "inActive",
  },
  {
    "s.no": 10,
    petName: "Bella",
    breed: "Maine Coon",
    petOwnerName: "Sophia Moore",
    mobile: "999-888-7777",
    address: "2023-10-20",
    deceasedStatus: "Yes",
    action: "View",
    petStatus: "active",
  },
  {
    "s.no": 11,
    petName: "Rocky",
    breed: "German Shepherd",
    petOwnerName: "Robert Johnson",
    mobile: "555-987-6543",
    address: "2023-11-20",
    deceasedStatus: "No",
    action: "Edit",
    petStatus: "active",
  },
  {
    "s.no": 12,
    petName: "Lucy",
    breed: "Siberian Husky",
    petOwnerName: "Ava Wilson",
    mobile: "222-111-3333",
    address: "2023-12-05",
    deceasedStatus: "No",
    action: "Delete",
    petStatus: "active",
  },
  {
    "s.no": 13,
    petName: "Leo",
    breed: "Rottweiler",
    petOwnerName: "Ethan Davis",
    mobile: "777-666-5555",
    address: "2024-01-15",
    deceasedStatus: "No",
    action: "View",
    petStatus: "active",
  },
  {
    "s.no": 14,
    petName: "Zoe",
    breed: "Shih Tzu",
    petOwnerName: "Grace Smith",
    mobile: "333-555-7777",
    address: "2024-02-28",
    deceasedStatus: "Yes",
    action: "Edit",
    petStatus: "active",
  },
  {
    "s.no": 15,
    petName: "Milo",
    breed: "Boxer",
    petOwnerName: "Liam Anderson",
    mobile: "999-444-8888",
    address: "2024-03-10",
    deceasedStatus: "No",
    action: "Delete",
    petStatus: "active",
  },
  {
    "s.no": 16,
    petName: "Cleo",
    breed: "Bengal",
    petOwnerName: "Chloe Moore",
    mobile: "777-999-1111",
    address: "2024-04-15",
    deceasedStatus: "No",
    action: "View",
    petStatus: "active",
  },
  {
    "s.no": 17,
    petName: "Cooper",
    breed: "Cavalier King Charles",
    petOwnerName: "Noah Wilson",
    mobile: "555-666-7777",
    address: "2024-05-20",
    deceasedStatus: "Yes",
    action: "Edit",
    petStatus: "active",
  },
  {
    "s.no": 18,
    petName: "Nala",
    breed: "Scottish Fold",
    petOwnerName: "Mia Davis",
    mobile: "111-222-3333",
    address: "2024-06-25",
    deceasedStatus: "No",
    action: "Delete",
    petStatus: "active",
  },
  {
    "s.no": 19,
    petName: "Oliver",
    breed: "Dalmatian",
    petOwnerName: "Sophie Johnson",
    mobile: "888-777-5555",
    address: "2024-07-30",
    deceasedStatus: "No",
    action: "View",
    petStatus: "active",
  },
  {
    "s.no": 20,
    petName: "Mittens",
    breed: "Persian",
    petOwnerName: "Eva Martinez",
    mobile: "999-111-2222",
    address: "2024-08-05",
    deceasedStatus: "No",
    action: "Delete",
    petStatus: "active",
  },
];

export default petData;
