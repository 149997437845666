import React, { useState } from "react";
import {
  Button,
  DatePicker,
  Input,
  List,
  Pagination,
  Skeleton,
  Space,
  Table,
} from "antd";
import { useGetVSPRequestsQuery } from "./vspRequestApi";
import ApplicationStatus from "../../components/ui/ApplicationStatus/ApplicationStatus";
import FilterBy from "../../components/ui/FilterBy/FilterBy";
import SearchBar from "../../components/ui/SearchBar/SearchBar";
import TableDropdown from "../../components/ui/TableDropdown/TableDropdown";
import { useNavigate } from "react-router-dom";

const ColumnWrapper = Table.Column;

const Column = (props) => {
  return (
    <ColumnWrapper
      {...props}
      render={(text) => {
        if (!text) {
          return <span>-</span>;
        }
        return text;
      }}
    />
  );
};

const tableOptions = [
  {
    label: "View Details",
    key: "view-details",
  },
];

const VSPRequestsTable = () => {
  const [statusFilter, setStatusFilter] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const options = {};
  if (statusFilter) {
    options.isEnabled = statusFilter === "enabled";
  }
  if (searchQuery) {
    options.search = searchQuery;
  }
  if (page) {
    options.page = page;
  }

  const { data } = useGetVSPRequestsQuery(options);
  const { items: VSPRequests, total } = data || {};

  const handleFilterChange = (value) => {
    setStatusFilter(value);
  };

  const handleSearchUpdated = (value) => {
    setSearchQuery(value);
  };

  const handleClick = async (e, id) => {
    navigate(`/vsp-management/requests/${id}`);
  };

  return (
    <div className="page-wrapper">
      <div className="filter-section mb-5">
        <div className="d-block d-md-flex justify-content-between align-items-center">
          <div className="single-filter mb-3 mb-md-0">
            {/* <FilterBy
              placeholder={"Status"}
              options={[
                { label: "Enabled", value: "enabled" },
                { label: "Disabled", value: "disabled" },
              ]}
              onChange={handleFilterChange}
            /> */}
          </div>
          <SearchBar
            placeholder="Search VSPs by Number and Email"
            value={searchQuery}
            onChange={handleSearchUpdated}
          />
        </div>
      </div>
      {console.log(VSPRequests)}
      <Table
        dataSource={VSPRequests}
        rowKey={"id"}
        onChange={(pagination) => setPage(pagination.current)}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 25,
          showSizeChanger: false,
          className: "tb-pagination",
          total: total,
        }}
      >
        <Column title="Tail No." dataIndex="id" key="id" />
        <Column title="Vet Id" dataIndex="panelUserId" key="id" />
        <Column title="Vet Name" dataIndex="basicInfo.name" key="name" />
        <Column
          title="Mobile Number"
          dataIndex="basicInfo.phone"
          key="mobileNumber"
        />
        <Column title="Email" dataIndex="basicInfo.email" key="email" />
        <Column
          title="Practice(years)"
          dataIndex="practiceYears"
          key="practice"
        />
        <Column
          title="DVM License Number"
          dataIndex="DVMLicenceNumber"
          key="licenseNumber"
        />
        <Column
          title="State Issued"
          dataIndex="stateIssued"
          key="stateIssued"
        />
        <Column
          title="Application Status"
          dataIndex="applicationStatus"
          key="applicationStatus"
          render={(text, record) => {
            return <ApplicationStatus status="pending" label={text} />;
          }}
        />
        {/* <Column title="Documents" dataIndex="documents" key="documents" /> */}

        <Column
          title="Action"
          dataIndex="action"
          key="action"
          render={(text, record) => {
            return (
              <TableDropdown
                items={tableOptions}
                onClick={(action) => handleClick(action, record.id)}
              />
            );
          }}
        />
      </Table>
    </div>
  );
};

export default VSPRequestsTable;
