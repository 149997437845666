import React, { useEffect } from "react";
import AuthLayout from "../components/layouts/AuthLayout/AuthLayout";
import LoginForm from "../components/auth/LoginForm/LoginForm";
import useAppPlatform from "../hooks/useAppPlatform";
import { useSelector } from "react-redux";
import { selectUser } from "../utils/selectors";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const platform = useAppPlatform({ vsp: "vsp", bcp: "bcp" });
  return (
    <AuthLayout>
      {platform === "vsp" && (
        <LoginForm
          subheading={"Log in as veterinary service provider"}
          loginAs="vsp"
        />
      )}
      {platform === "bcp" && (
        <LoginForm subheading={"Login In as Master Admin"} />
      )}
    </AuthLayout>
  );
}
