import React, { useState } from "react";
import {
  Button,
  DatePicker,
  Input,
  List,
  Pagination,
  Skeleton,
  Space,
  Table,
  Form,
  Row,
  Col,
  Tag,
} from "antd";
import { useFetchPetOwnersQuery } from "./petOwnersApi";
import "./PetOwnersTable.scss";
import FilterBy from "../../components/ui/FilterBy/FilterBy";
import SearchBar from "../../components/ui/SearchBar/SearchBar";
import ApplicationStatus from "../../components/ui/ApplicationStatus/ApplicationStatus";
import TableDropdown from "../../components/ui/TableDropdown/TableDropdown";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useFetchVetProfileQuery } from "../../api/onboarding";

const ColumnWrapper = Table.Column;

const tableOptions = [
  {
    label: "View Details",
    key: "view-details",
  },
];
const timezoneOffset = new Date().getTimezoneOffset();
console.log("timezoneOffset", timezoneOffset);
const Column = (props) => {
  return (
    <ColumnWrapper
      {...props}
      render={(text) => {
        if (!text) {
          return <span>-</span>;
        }
        return text;
      }}
    />
  );
};

const UsersList = () => {
  const [statusFilter, setStatusFilter] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [dateFilter, setDateFilter] = React.useState({
    start: null,
    end: null,
  });

  const timezoneOffset = new Date().getTimezoneOffset();

  console.log("dateFilter ===>", dateFilter);

  const options = {};
  if (statusFilter) {
    options.isEnabled = statusFilter === "enabled";
  }
  if (searchQuery) {
    options.search = searchQuery;
  }
  if (page) {
    options.page = page;
  }
  if (dateFilter.start) {
    options.startDate = dateFilter.start;
  }
  if (dateFilter.end) {
    options.endDate = dateFilter.end;
  }
  if (timezoneOffset) {
    options.timezoneOffset = timezoneOffset;
  }

  const { data, isFetching } = useFetchPetOwnersQuery(options);
  const { items: petOwners, total } = data || {};

  const handleFilterChange = (value) => {
    setStatusFilter(value);
  };

  const handleSearchUpdated = (value) => {
    setPage(1);
    console.log("handleSearchUpdated =====> ", value);
    setSearchQuery(value);
  };

  const handleClick = async (e, id) => {
    navigate(`/pet-owners/${id}`);
  };

  const disabledStartDate = (current) => {
    // Disable dates after today
    return current && current > moment().endOf("day");
  };
  const disabledEndDate = (current) => {
    // Can not select days before the start date
    return current && current < moment(dateFilter.start);
  };

  return (
    <div className="page-wrapper">
      <div className="filter-section mb-5">
        <div className="d-md-flex justify-content-between align-items-center">
          <div className="start-end-date-filter">
            <Form layout="vertical" className="w-100">
              <Row>
                <div
                  xs={24}
                  style={{
                    display: "flex",
                    gap: "1rem",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                  }}
                >
                  <Form.Item
                    label="Start Date"
                    className="filter-section mb-md-0"
                  >
                    <DatePicker
                      size="large"
                      disabledDate={disabledStartDate}
                      onChange={(e) => {
                        console.log(e);
                        setDateFilter({
                          ...dateFilter,
                          start: e ? e.format("YYYY-MM-DD") : null,
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="End Date"
                    className="px-md-3 filter-section  mb-md-0"
                  >
                    <DatePicker
                      disabledDate={disabledEndDate}
                      size="large"
                      onChange={(e) => {
                        console.log(e);
                        setDateFilter({
                          ...dateFilter,
                          end: e ? e.format("YYYY-MM-DD") : null,
                        });
                      }}
                    />
                  </Form.Item>

                  <div
                    className="justify-content-end "
                    style={{ width: "300px" }}
                  >
                    <SearchBar
                      placeholder="Search Pet Owners"
                      value={searchQuery}
                      onChange={handleSearchUpdated}
                    />
                  </div>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </div>
      <div className="table-wrapper">
        <Table
          dataSource={petOwners}
          rowKey={"id"}
          onChange={(pagination) => setPage(pagination.current)}
          pagination={{
            position: ["bottomCenter"],
            pageSize: 25,
            showSizeChanger: false,
            className: "tb-pagination",
            total: total,
          }}
          rowClassName={"animate__animated animate__fadeIn"}
        >
          <Column title="Tail No." dataIndex="id" key="id" />
          <Column
            title="Pet Owner Name"
            dataIndex="name"
            key="name"
            render={(text, record) => {
              return (
                <span>
                  {record.firstName} {record.lastName}
                </span>
              );
            }}
          />
          <Column
            title="No. of Pets"
            dataIndex="noOfPets"
            key="noOfPets"
            render={(text, record) => {
              return <span>{record.petCount || 0}</span>;
            }}
          />
          {/* <Column title="Phone Number" dataIndex="phone" key="phone" /> */}
          <Column title="Email" dataIndex="email" key="email" />
          {/* <Table.Column
          title="Status"
          dataIndex="status"
          key="status"
          render={(text, record) => {
            return (
              <ApplicationStatus
                status={record.isActive ? "success" : "failed"}
                label={record.isActive ? "Joined" : "Invited"}
              />
            );
          }}
        /> */}
          <Table.Column
            title="Created At"
            dataIndex="createdAt"
            key="createdAt"
            render={(text) => (
              <Tag color="pink">{moment(text).format("YYYY-MM-DD")}</Tag>
            )}
          />
          <Column
            title="Action"
            dataIndex="action"
            key="action"
            render={(text, record) => {
              return (
                <TableDropdown
                  items={tableOptions}
                  onClick={(action) => handleClick(action, record.id)}
                />
              );
            }}
          />
        </Table>
      </div>
    </div>
  );
};

export default UsersList;
