import React from "react";
import { Col, Row, Form, Input, Button, Divider, Checkbox, Alert } from "antd";
import "./LoginForm.scss";
import { Link, useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../../api/auth";
import { saveUserToLocalStorage } from "../../../utils/localstorage";
import { app_platform } from "../../../config";

export default function LoginForm({
  heading,
  subheading,
  loginAs = "bcp",
  children,
}) {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    loading: false,
  });

  const [login] = useLoginMutation();

  const disableLoading = () => {
    setTimeout(() => {
      setState({ ...state, loading: false });
    }, 500);
  };

  const handleLoginForm = (values) => {
    // Convert email to lowercase
    const lowercaseValues = {
      ...values,
      email: values.email.toLowerCase(),
    };

    setState({ ...state, loading: true });

    login(lowercaseValues)
      .then((res) => {
        const { data: response } = res.data;
        console.log("data", response);
        saveUserToLocalStorage(response, response["api-token"]);
        if (app_platform === "vsp") {
          if (response.stage === "onboarding" || response.stage === "pending") {
            navigate("/vsp/onboarding?data=true");
          }
          if (response.stage === "approved") {
            window.location.href = "/vsp/home";
          }
        } else {
          navigate("/home");
        }
      })
      .catch(() => {
        disableLoading();
      });
  };
  return (
    <div
      className="login-form auth-form"
      style={{ padding: "0px", paddingTop: "50px" }}
    >
      <img
        src="/logo-carson-bear.png"
        className=" h-12 w-68  mx-auto"
        alt="Logo"
      />

      {/* <h1>Howdy!</h1> */}
      <Row justify="center">
        <Col span={10}>
          <h1>Howdy!</h1>
          <h2>{subheading}</h2>
        </Col>
      </Row>
      <div className="form-wrapper">
        <Row justify="center">
          <Col span={10}>
            <div className="form">
              <Form layout="vertical" onFinish={handleLoginForm}>
                <div className="form-group">
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter a valid email address",
                      },
                      {
                        required: true,
                        message: "Email is required",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="form-control"
                      placeholder="Enter your email here"
                    />
                  </Form.Item>
                </div>
                <div className="form-group password-section">
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Password is required",
                      },
                      {
                        min: 8,
                        message: "Password must be at least 8 characters",
                      },
                    ]}
                  >
                    <Input
                      type="password"
                      size="large"
                      className="form-control"
                      placeholder="Enter your password here"
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item>
                    <Link
                      to="/forget-password"
                      className="login-form-forgot"
                      href=""
                    >
                      Forgot password
                    </Link>
                  </Form.Item>
                </div>
                <div className="form-group button-section">
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      className="login-btn"
                      loading={state.loading}
                    >
                      Log In
                    </Button>
                  </Form.Item>
                </div>
                {loginAs === "vsp" && (
                  <div className="form-group signup-section">
                    <p type="text">
                      Don't have an Account?{" "}
                      <Link to={"/signup"} className="signup">
                        Sign Up
                      </Link>
                    </p>
                  </div>
                )}
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
