import React, { useState } from "react";
import { Col, Row, Form, Input, Button, message } from "antd";
import { Link, useHistory, useNavigate } from "react-router-dom";
import axios from "axios";

import "./ForgetPasswordForm.scss";
import useAppPlatform from "../../../hooks/useAppPlatform";

export default function ForgetPasswordForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const platform = useAppPlatform({ vsp: "vsp", bcp: "bcp" });

  const onFinish = async () => {
    const BCP = "/bcp/forget-password";
    const VSP = "/vsp/forget-password";

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_SERVER}${platform === "bcp" ? BCP : VSP}`,
        { email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 && response.data.success) {
        const passwordResetToken = response.data.data.passwordResetToken;

        // localStorage.setItem("passwordResetToken", passwordResetToken);

        message.success("Password reset link sent successfully");

        // navigate(`/passwordresettoken/${passwordResetToken}`);
      } else {
        message.error("Failed to send password reset link");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const validateEmail = (rule, value, callback) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (value && !emailRegex.test(value)) {
      callback("Please enter a valid email address");
    } else {
      callback();
    }
  };

  return (
    <div className="forget-password-form auth-form">
      <div className="logo-wrapper">
        <img src="/logo-carson-bear.png" />
      </div>
      <Row justify="center">
        <Col span={12}>
          <h1>Reset password</h1>
          <h4>
            Enter your email address and we'll send you a link with which you
            can reset your password
          </h4>
        </Col>
      </Row>
      <div className="form-wrapper">
        <Row justify="center">
          <Col span={10}>
            <div className="form">
              <Form layout="vertical" onFinish={onFinish}>
                <div className="form-group">
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "Please enter your email" },
                      { validator: validateEmail },
                    ]}
                  >
                    <Input
                      size="large"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value.toLowerCase())}
                    />
                  </Form.Item>
                </div>
                <div className="form-group button-section">
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      className="login-btn"
                    >
                      Send Link
                    </Button>
                  </Form.Item>
                </div>

                <div className="form-group signup-section">
                  <p type="text">
                    Return to
                    <Link to="/login" className="signup">
                      "Log In"
                    </Link>
                  </p>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
