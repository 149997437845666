import React, { useState, useEffect } from "react";
import AppLayout from "../components/layouts/AppLayout/AppLayout";
import UsersList from "../features/users/UserList";
import { Col, Row, Spin } from "antd";
import TableBtn from "../components/ui/TableBtn/TableBtn";
import { BulkUploadIcon, SaveCsvIcon } from "../components/Icons";
import { useInvitationCount } from "./dashboard-api/useInvitationCount";
import { usePatientJoinedCount } from "./dashboard-api/usePatientJoinedCount";
import axios from "axios";

const StatCard = ({ value, label }) => {
  return (
    <div className="stat-card">
      <div className="stat-card-label">{label}</div>
      <div className="stat-card-value">{value}</div>
    </div>
  );
};

export default function Dashboard() {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchDashboardData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_SERVER}/bcp/dashboard-summery`
        );
        setDashboardData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        setLoading(false);
      }
    }
    fetchDashboardData();
  }, []);

  return (
    <AppLayout title={"Dashboard"}>
      <div className="stat-cards-wrapper mb-5">
        <Row gutter={[20, 20]}>
          <Col span={8} xs={12}>
            <Spin spinning={loading} className="mb-10">
              {dashboardData && (
                <StatCard
                  value={dashboardData.nutritionistAppointments}
                  label={"Nutritionist Appointments"}
                />
              )}
            </Spin>
          </Col>
          <Col span={8} xs={12}>
            <Spin spinning={loading} className="mb-10">
              {dashboardData && (
                <StatCard
                  value={dashboardData.tomorrowAppointments}
                  label={"Tomorrow Appointments"}
                />
              )}
            </Spin>
          </Col>
          <Col span={8} xs={12}>
            <Spin spinning={loading} className="mb-10">
              {dashboardData && (
                <StatCard
                  value={dashboardData.thisWeekAppointments}
                  label={"This Week Appointments"}
                />
              )}
            </Spin>
          </Col>
          {/* <Col span={12}>
            <StatCard value={100} label={"Total Number of Invitations Sent"} />
          </Col> */}
          <Col span={8} xs={12}>
            <Spin spinning={loading} className="mb-10">
              {dashboardData && (
                <StatCard
                  value={dashboardData.totalPatientsJoined}
                  label={"Total number of Patients Joined"}
                />
              )}
            </Spin>
          </Col>
        </Row>
      </div>
      <div className="mt-5 pt-5" />
      <UsersList />
    </AppLayout>
  );
}
