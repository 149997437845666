import { apiClient } from "../../api/apiClient";

const TAG = "VSP";
const baseUrl = "/bcp/vsps";

export const VSPApi = apiClient.injectEndpoints({
  endpoints: (builder) => ({
    fetchVSP: builder.query({
      query: (params) => ({
        url: baseUrl,
        params,
      }),
      transformResponse: (response) => {
        return {
          total: response.total,
          items: response.data,
        };
      },
      providesTags: [TAG],
    }),
    getSingleVSP: builder.query({
      query: (id) => `bcp/vsps/${id}`,
      providesTags: [TAG],
    }),
    updateSingleVSP: builder.mutation({
      query: (user) => ({
        url: `bcp/vsps/${user.id}`,
        method: "PATCH",
        body: user,
      }),
      invalidatesTags: [TAG],
    }),
  }),
});

export const {
  useFetchVSPQuery,
  useGetSingleVSPQuery,
  useUpdateSingleVSPMutation,
} = VSPApi;
