import React, { useEffect } from 'react'
import AuthLayout from '../components/layouts/AuthLayout/AuthLayout'
import SignupForm from '../components/auth/SignupForm/SignupForm'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUser } from '../utils/selectors';

export default function Signup() {

  const user = useSelector(selectUser);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if(user) {
  //     if(user.stage === 'onboarding') {
  //       navigate('/vsp/onboarding');
  //     }
  //   }
  // }, [navigate, user]);

  return (
    // <div>Signup</div>
    <AuthLayout>
      <SignupForm />
    </AuthLayout>
  )
}
