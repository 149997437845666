import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaInfoCircle } from "react-icons/fa";
import "./WellnessPlanTable.scss";
import { faker } from "@faker-js/faker";
import moment from "moment";
import { RiPushpinFill } from "react-icons/ri";
import { FaEye } from "react-icons/fa";
import {
  Modal,
  Form,
  Input,
  Button,
  Radio,
  Select,
  DatePicker,
  Table,
  Divider,
  Tag,
} from "antd";
import ApplicationStatus from "../../ui/ApplicationStatus/ApplicationStatus";
import SearchBar from "../../ui/SearchBar/SearchBar";
import { useFetchVetProfileQuery } from "../../../api/onboarding";
import { useFetchWellnessDollarsQuery } from "./wellnessPlanApi";

const { Column } = Table;

export default function WellnessPlanTable() {
  const { data: vetProfileData, isLoading: vetProfileLoading } =
    useFetchVetProfileQuery();
  const [wellnessData, setWellnessData] = useState([]);

  const [wellnessDataDash, setWellnessDataDash] = useState([]);
  const [wellnessPetsActive, setWellnessPetsActive] = useState(null);
  console.log(wellnessPetsActive, "wellnessPetsActive");
  const [searchQueryPetName, setSearchQueryPetName] = useState("");
  const [searchQueryEmail, setSearchQueryEmail] = useState("");
  const [monthlyModalVisible, setMonthlyModalVisible] = useState(false);
  const [quarterlyModalVisible, setQuarterlyModalVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10, // Set your initial page size here
    total: 0,
  });

  useEffect(() => {
    if (vetProfileData && vetProfileData.id) {
      fetchWellnessData(vetProfileData.id);
    }
  }, [vetProfileData, searchQueryPetName, searchQueryEmail]);

  const fetchWellnessData = async (profileId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_SERVER}/clinics/wellness-dollars/vsp/${profileId}`,
        {
          params: {
            search: `${searchQueryPetName}${searchQueryEmail}`,
            // page: pagination.current, // Include current page
            // pageSize: pagination.pageSize, // Include page size
          },
        }
      );
      // Set wellnessData to the estimatedWellnessDollars array
      setWellnessData(response.data.data.estimatedWellnessDollars);
      setWellnessDataDash(response.data.data.summaryStatement);
      setWellnessPetsActive(
        response.data.data.summaryStatement.currentMonthActivePets
      );
      // setPagination({ ...pagination, total: response.data.total }); // Update total count for pagination
    } catch (error) {
      console.error("Error fetching wellness data:", error);
    }
  };
  const handleTableChange = (pagination) => {
    setPagination(pagination); // Update pagination state
  };
  const handleSearchPetName = (value) => {
    setSearchQueryPetName(value);
  };

  const handleSearchEmail = (value) => {
    setSearchQueryEmail(value);
  };

  return (
    <div className="wellness-plan-table">
      <div className="form-wrapper">
        <div className="top-section">
          <div className="FilterBoxCont">
            {wellnessPetsActive >= 10 && (
              <div className="card-details">
                <RiPushpinFill className="icon-pin" />
                <p className="headingone">
                  My Clinic: Upcoming Investment Estimate
                </p>
                <div className="col">
                  <p>
                    Dogs - full month membership:{" "}
                    <span>{wellnessDataDash.currentMonthActivePets}</span>
                  </p>
                  <p>
                    Investment payment per dog - estimated{" "}
                    <span className="span-">
                      {" "}
                      {`$ ${wellnessDataDash.investmentPaymentPerDog}`}
                    </span>
                  </p>
                  <p>
                    Forecasted Monthly Payment:{" "}
                    <span className="span-">
                      {" "}
                      {`$ ${wellnessDataDash.monthlyPayment}`}
                    </span>
                  </p>
                  <p>
                    Forecasted Quarterly Payment:{" "}
                    <span className="span-">
                      {" "}
                      {`$ ${wellnessDataDash.quarterlyPayment}`}
                    </span>
                  </p>
                </div>
              </div>
            )}

            {wellnessPetsActive < 10 && (
              <div className="card-details">
                <RiPushpinFill className="icon-pin" />
                <p className="headingone">
                  My Clinic: Upcoming Investment Estimate
                </p>
                <div className="col">
                  <p>
                    Dogs - full month membership:{" "}
                    <span> {wellnessPetsActive}</span>
                  </p>
                  <p className="pets-10">
                    The application of wellness dollars begins once the number
                    of pets reaches 10
                  </p>
                </div>
              </div>
            )}

            <div className="FilterBox"></div>

            <div className="FilterBox">
              <label>Search by pet name</label>
              <SearchBar
                placeholder="Pet name "
                onChange={handleSearchPetName}
              />
            </div>

            <div className="FilterBox">
              <label>Search by pet parent email</label>
              <SearchBar
                placeholder="Pet parent email"
                onChange={handleSearchEmail}
              />
            </div>
          </div>
        </div>
        <div className="FilterBox"></div>
        <div className="FilterBox"></div>
        <div className="FilterBox"></div>
      </div>
      <Table
        dataSource={wellnessData}
        // pagination={pagination}
        onChange={handleTableChange}
      >
        <Column
          title="S.No"
          dataIndex="sectionno"
          key="sectionno"
          render={(text, record, index) => index + 1}
        />
        <Column title="Vet Name" dataIndex="vet" key="vet" />
        <Column title="Pet Name" dataIndex="petName" key="petName" />
        <Column
          title="Subscription Date"
          dataIndex="subscriptionCreatedAt"
          key="subscriptionCreatedAt"
          render={(text) => moment(text).format("YYYY-MM-DD")}
        />
        <Column title="Email" dataIndex="userEmail" key="userEmail" />
        <Column
          title="Subscription"
          dataIndex="isSubscriptionActive"
          key="isSubscriptionActive"
          render={(isActive) => (
            <Tag color={isActive ? "green" : "red"}>
              {isActive ? "Active" : "Inactive"}
            </Tag>
          )}
        />
        <Column
          title="Eligible For This Month"
          dataIndex="isEligibleForThisMonth"
          key="isEligibleForThisMonth"
          render={(isActive) => (
            <Tag color={isActive ? "green" : "red"}>
              {isActive ? "True" : "False"}
            </Tag>
          )}
        />

        {wellnessPetsActive >= 10 && (
          <>
            <Column
              title={
                <div
                  onClick={() => setMonthlyModalVisible(true)}
                  style={{ cursor: "pointer" }}
                >
                  Qualifying Monthly Amount <FaInfoCircle className="eye" />
                </div>
              }
              dataIndex="currentMonthAmount"
              key="currentMonthAmount"
              onCell={() => {
                return {
                  onClick: () => setMonthlyModalVisible(true),
                };
              }}
            />
            <Column
              title={
                <div
                  onClick={() => setQuarterlyModalVisible(true)}
                  style={{ cursor: "pointer" }}
                >
                  Current Quarterly Estimate <FaInfoCircle className="eye" />
                </div>
              }
              dataIndex="currentQuarterAmount"
              key="currentQuarterAmount"
              onCell={() => {
                return {
                  onClick: () => setQuarterlyModalVisible(true),
                };
              }}
            />
          </>
        )}
      </Table>

      <Modal
        title="Qualifying Monthly Amount"
        visible={monthlyModalVisible}
        onCancel={() => setMonthlyModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setMonthlyModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <p style={{ fontSize: "1rem", fontWeight: "600" }}>
          The qualifying monthly amount is the amount you earn in investment
          dollars per dog. A dog must be subscribed for an entire calendar month
          to qualify for monthly investment credit. This is only an estimate,
          not a guarantee of payment.
        </p>
        <p>
          (For example, Milo becomes Dr. Brown's 89th subscriber on Jan 25th,
          2024, and remains on the program for the remainder of 2022 and 2024.
          Milo would qualify for a $10 monthly investment credit to Dr. Brown
          from February 2024 until the end of the last full month of Milo's
          subscription to Carson&Bear™).
        </p>
      </Modal>

      {/* Modal for Current Quarterly Estimate */}
      <Modal
        title="Current Quarterly Estimate"
        visible={quarterlyModalVisible}
        onCancel={() => setQuarterlyModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setQuarterlyModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <p style={{ fontSize: "1rem", fontWeight: "600" }}>
          The current quarterly estimate is the real-time estimate of your
          upcoming quarterly investment payment. The quarterly payment is the
          sum of all qualifying monthly credits in a quarterly fscal period.
          This is only an estimate, not a guarantee of payment.
        </p>
        <p>
          (For example, Dr. Brown has 89th dogs subscribed for the entire month
          of Jan 2022, 96 dogs subscribed for the entire month of Feb 2022, and
          105 dogs subscribed for the entire month of Jan 2022. Therefore, Dr.
          Brown would receive $10 per qualifed dog monthly in the quarter.
          January payment would equal $890, the February payment would equal
          $960, and the March payment would equal $1,050. The quarterly payment
          would be $2,900.00.)
        </p>
      </Modal>
    </div>
  );
}
