import { apiClient } from "../../api/apiClient";

const TAG = "VSP";
const baseUrl = "/bcp/vsps/requests";

export const VSPRequestApi = apiClient.injectEndpoints({
    endpoints: (builder) => ({
        getVSPRequests: builder.query({
            query: (params) => ({
                url: baseUrl,
                params,
            }),
            transformResponse: (response) => {
                return {
                    total: response.total,
                    items: response.data,
                };
            },
            providesTags: [TAG],
        }),
        getVSPSingleRequest: builder.query({
            query: (id) => `bcp/vsps/requests/${id}`,
            transformResponse: response => {
                return response.data
            },
            providesTags: [TAG],
        }),
        approveRejectRequest: builder.mutation({
            query: ({ id, action }) => ({
                url: `${baseUrl}/${id}/${action}`,
                method: "PUT",
            }),
            invalidatesTags: [TAG],
        }),
    }),
});

export const {
    useGetVSPRequestsQuery,
    useGetVSPSingleRequestQuery,
    useApproveRejectRequestMutation
} = VSPRequestApi;
