import React from 'react'
import AuthLayout from '../components/layouts/AuthLayout/AuthLayout'
import ForgetPasswordForm from '../components/auth/ForgetPasswordForm/ForgetPasswordForm'

export default function ForgetPassword() {
    return (
        <AuthLayout>
            {/* <h1>Howdy!</h1> */}
            <ForgetPasswordForm />

           
        </AuthLayout>
    )
}
