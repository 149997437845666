import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./SideNav.scss";
import { ImHourGlass } from "react-icons/im";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosArrowDropdown } from "react-icons/io";
import {
  BCPNutritionistIcon,
  ContentIcon,
  DashboardIcon,
  NutritionistPlanIcon,
  PetOwnersIcon,
  PetsIcons,
  SettingsIcon,
  Stock,
  VspIcon,
  WellnessPracticeIcon,
} from "../Icons";

import classNames from "classnames";
import useAppPlatform from "../../hooks/useAppPlatform";

const BcpLinks = [
  {
    label: "Dashboard",
    icon: <DashboardIcon />,
    path: "/dashboard",
  },
  {
    label: "VSP Management",
    icon: <VspIcon />,
    path: "/vsp-management",
  },
  {
    label: "Pet Owners",
    icon: <PetOwnersIcon />,
    path: "/pet-owners",
  },
  {
    label: "BCP Nutritionist",
    icon: <BCPNutritionistIcon />,
    path: "/bcp-nutritionists",
  },
  // {
  //   label: "Nutritionist Plan",
  //   icon: <NutritionistPlanIcon />,
  //   path: "/nutritionist-plan",
  // },
  {
    label: "Wellness Practice",
    icon: <WellnessPracticeIcon />,
    path: "/wellness-practice",
  },
  {
    label: "Content",
    icon: <ContentIcon />,
    path: "/content",
  },
  {
    label: "Cancelled Subscription",
    icon: <ContentIcon />,
    path: "/cancelled-pet",
  },
];

const VspLinks = [
  {
    label: "Dashboard",
    icon: <DashboardIcon />,
    path: "/vsp/home",
  },
  {
    label: "Pet Visits",
    icon: <PetsIcons />,
    path: "/vsp/pet-visits",
  },
  {
    label: "Pet Owners",
    icon: <PetOwnersIcon />,
    path: "/vsp/pet-owners",
    children: [
      {
        label: "Invited Pet Owners",
        path: "/vsp/pet-owners/invited",
        icon: <ImHourGlass style={{ fontSize: "25px" }} />,
      },
    ],
  },

  {
    label: "Nutritionist Plan",
    icon: <NutritionistPlanIcon />,
    path: "/vsp/nutritionist-plan",
  },
  {
    label: "Wellness Practice",
    icon: <WellnessPracticeIcon />,
    path: "/vsp/wellness-practice",
  },
  {
    label: "Investment Planning Calculator",
    icon: <Stock />,
    path: "https://carsonandbearpets.com/vsp-planning-investment-wellness-credits/",
  },
];

export default function SideNav() {
  const location = useLocation();
  const [showPetOwnersDropdown, setShowPetOwnersDropdown] = useState(false);

  const LinksArray = useAppPlatform({ vsp: VspLinks, bcp: BcpLinks }) || [];

  const togglePetOwnersDropdown = () => {
    setShowPetOwnersDropdown(!showPetOwnersDropdown);
  };

  const handleMouseEnter = (link) => {
    if (link.label === "Pet Owners") {
      setShowPetOwnersDropdown(true);
    }
  };

  const handleMouseLeave = (link) => {
    if (link.label === "Pet Owners") {
      setShowPetOwnersDropdown(false);
    }
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="side-nav">
      <ul>
        {LinksArray.map((link, index) => {
          const selected = location.pathname.includes(link.path);
          return (
            <li
              key={index}
              className={classNames({
                selected,
                "has-children": link.children && link.label === "Pet Owners",
                "dropdown-open":
                  showPetOwnersDropdown && link.label === "Pet Owners",
              })}
              onMouseEnter={() => handleMouseEnter(link)}
              onMouseLeave={() => handleMouseLeave(link)}
            >
              <Link
                to={link.path}
                onClick={() => {
                  if (link.label === "Pet Owners") {
                    togglePetOwnersDropdown();
                  }
                }}
              >
                <span className="icon">{link.icon}</span>
                <span className="label">{link.label}</span>
                {link.children && link.label === "Pet Owners" && (
                  <span className="dropdown-icon">
                    <IoIosArrowDropdown /> {/* Use your dropdown icon here */}
                  </span>
                )}
              </Link>
              {link.children &&
                link.label === "Pet Owners" &&
                showPetOwnersDropdown && (
                  <ul className="sub-menu">
                    {link.children.map((child, idx) => (
                      <li key={idx}>
                        <Link
                          to={child.path}
                          onClick={stopPropagation}
                          style={{
                            backgroundColor: "#FADB99",
                            borderColor: "black",
                            borderWidth: "1px",
                            marginTop: "10px",
                          }}
                        >
                          <span className="icon">{child.icon}</span>
                          <span className="label">{child.label}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
