import { Table, Tag, Dropdown, Menu, Button, Modal, Input } from "antd";
import React, { useState } from "react";
import SearchBar from "../../components/ui/SearchBar/SearchBar";
import FilterBy from "../../components/ui/FilterBy/FilterBy";
import ApplicationStatus from "../../components/ui/ApplicationStatus/ApplicationStatus";
import cancelledSubscriptionData from "./cancelledSubscriptionData";
import { HiOutlineDotsVertical } from "react-icons/hi";

const { TextArea } = Input;

const Column = Table.Column;

const CancelledSubscription = () => {
  const [searchQuery, setSearchQuery] = useState(null);
  const [initiateModalVisible, setInitiateModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [completeModalVisible, setCompleteModalVisible] = useState(false);

  const [refundAmount, setRefundAmount] = useState("");

  const [cancelReason, setCancelReason] = useState("");
  const [completeDescription, setCompleteDescription] = useState("");
  const [petRefundDetails, setPetRefundDetails] = useState({});
  const [record, setRecord] = useState(null);

  const handleSearchUpdated = (value) => {
    setSearchQuery(value);
  };

  const handleInitiateModalOk = (record) => {
    // Calculate refundable tax
    const tax = parseFloat(refundAmount) * 0.05;

    // Set initiate refund date to current date
    const currentDate = new Date().toISOString().slice(0, 10);

    // Update refund details for the particular pet
    const updatedPetRefundDetails = {
      ...petRefundDetails,
      [record.id]: {
        refundAmount,
        refundableTax: tax,
        initiateRefundDate: currentDate,
      },
    };
    setPetRefundDetails(updatedPetRefundDetails);

    // Implement logic to continue with refund initiation
    console.log("Refund initiated with amount:", refundAmount);
    setInitiateModalVisible(false);
  };

  const handleInitiateModalCancel = () => {
    setInitiateModalVisible(false);
  };

  const handleCancelModalOk = () => {
    console.log("Refund canceled with reason:", cancelReason);
    setCancelModalVisible(false);
  };

  const handleCancelModalCancel = () => {
    setCancelModalVisible(false);
  };

  const handleCompleteModalOk = () => {
    console.log("Refund completed with description:", completeDescription);
    setCompleteModalVisible(false);
  };

  const handleCompleteModalCancel = () => {
    setCompleteModalVisible(false);
  };

  const handleAction = (record, action) => {
    if (action === "initiate") {
      setInitiateModalVisible(true);
      setRecord(record);
    } else if (action === "cancel") {
      setCancelModalVisible(true);
      setRecord(record);
    } else if (action === "complete") {
      setCompleteModalVisible(true);
      setRecord(record);
    }
  };

  const menu = (record) => (
    <Menu onClick={({ key }) => handleAction(record, key)}>
      <Menu.Item key="initiate">Initiate Refund</Menu.Item>
      <Menu.Item key="cancel">Cancel Refund</Menu.Item>
      <Menu.Item key="complete">Complete Refund</Menu.Item>
    </Menu>
  );

  return (
    <div className="page-wrapper">
      <div className="filter-section mb-5">
        <div className="d-block d-md-flex justify-content-between align-items-center">
          <div className="single-filter mb-4 mb-md-0">
            <FilterBy
              placeholder={"Status"}
              options={[
                { label: "Enabled", value: "enabled" },
                { label: "Disabled", value: "disabled" },
              ]}
            />
          </div>

          <SearchBar
            placeholder="Search Nutritionists"
            value={searchQuery}
            onChange={handleSearchUpdated}
          />
        </div>
      </div>

      <Table
        dataSource={cancelledSubscriptionData}
        rowKey={"id"}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30", "40"],
          className: "tb-pagination",
        }}
      >
        <Column title="S No." dataIndex="id" key="id" />
        <Column title="Pet Name" dataIndex="name" key="name" />
        <Column title="Pet Owner" dataIndex="phone" key="phone" />
        <Column title="Shipping address" dataIndex="email" key="email" />
        <Column
          title="Cancel Status"
          dataIndex="action"
          key="action"
          render={(text) => (
            <Tag color={text === "Cancelled" ? "red" : "green"}>{text}</Tag>
          )}
        />
        <Column
          title="Initiate Refund Date"
          dataIndex="id"
          key="initiateRefundDate"
          render={(text, record) => (
            <Tag color="blue">
              {petRefundDetails[record.id]
                ? petRefundDetails[record.id].initiateRefundDate
                : "-"}
            </Tag>
          )}
        />
        <Column
          title="Refundable Amount"
          dataIndex="id"
          key="refundableAmount"
          render={(text, record) => (
            <Tag color="orange">
              {petRefundDetails[record.id]
                ? `$ ${petRefundDetails[record.id].refundAmount}`
                : "-"}
            </Tag>
          )}
        />
        <Column
          title="Refundable Tax"
          dataIndex="id"
          key="refundableTax"
          render={(text, record) => (
            <Tag color="purple">
              {petRefundDetails[record.id]
                ? `$ ${petRefundDetails[record.id].refundableTax}`
                : "-"}
            </Tag>
          )}
        />
        <Column
          title="Actions"
          key="actions"
          render={(text, record) => (
            <Dropdown overlay={() => menu(record)}>
              <HiOutlineDotsVertical className="cursor-pointer" />
            </Dropdown>
          )}
        />
      </Table>

      <Modal
        title="Initiate Refund"
        visible={initiateModalVisible}
        onOk={() => handleInitiateModalOk(record)}
        onCancel={handleInitiateModalCancel}
        footer={[
          <Button
            key="submit"
            onClick={() => handleInitiateModalOk(record)}
            className="w-full bg-orange-500 text-white"
          >
            Continue
          </Button>,
        ]}
      >
        <div className="my-10">
          <p className="text-md mb-3 font-bold">
            Please enter the refund amount:
          </p>
          <Input
            value={refundAmount}
            onChange={(e) => setRefundAmount(e.target.value)}
            placeholder="Refund Amount"
            prefix="$"
          />
        </div>
      </Modal>

      <Modal
        title="Cancel Refund"
        visible={cancelModalVisible}
        onOk={handleCancelModalOk}
        onCancel={handleCancelModalCancel}
        footer={[
          <Button
            key="submit"
            onClick={handleCancelModalOk}
            className="w-full bg-orange-500 text-white"
          >
            Continue
          </Button>,
        ]}
      >
        <div className="my-10">
          <p className="text-md mb-3 font-bold">
            Please enter the reason for cancellation:
          </p>
          <TextArea
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
            placeholder="Reason for cancellation"
            autoSize={{ minRows: 3, maxRows: 6 }}
          />
        </div>
      </Modal>

      <Modal
        title="Complete Refund"
        visible={completeModalVisible}
        onOk={handleCompleteModalOk}
        onCancel={handleCompleteModalCancel}
        footer={[
          <Button
            key="submit"
            onClick={handleCompleteModalOk}
            className="w-full bg-orange-500 text-white"
          >
            Continue
          </Button>,
        ]}
      >
        <div className="my-10">
          <p className="text-md mb-3 font-bold">
            Please provide a description for the completion:
          </p>
          <TextArea
            value={completeDescription}
            onChange={(e) => setCompleteDescription(e.target.value)}
            placeholder="Description"
            autoSize={{ minRows: 6, maxRows: 8 }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CancelledSubscription;
