import React from 'react'
import AuthLayout from '../components/layouts/AuthLayout/AuthLayout'
import ClinicInfoForm from '../components/onboarding/ClinicInfoForm/ClinicInfoForm'

export default function ClinicInfo() {
  return (
    <AuthLayout>
    <ClinicInfoForm />
  </AuthLayout>
  )
}
