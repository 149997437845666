import React from "react";
import AppLayout from "../components/layouts/AppLayout/AppLayout";
import VSPRequestsTable from "../features/vsp-requests/VSPRequests";

export default function VSPRequests() {
    return (
        <AppLayout
            title={"Requests"}
        >
            <VSPRequestsTable />
        </AppLayout>
    );
}
