import React from "react";
import AppLayout from "../components/layouts/AppLayout/AppLayout";
import PetOwnersDetailTable from "../features/pet-owners-detail/PetOwnersDetailTable";
import TableBtn from "../components/ui/TableBtn/TableBtn";
import { UploadVideoIcon } from "../components/Icons";
import SideDrawer from "../components/ui/SideDrawer/SideDrawer";
import PetDeceasedForm from "../features/pet-owners-detail/PetDeceasedForm/PetDeceasedForm";


export default function PetDeceased() {
    const [showAddDrawer, setShowAddDrawer] = React.useState(false);
    const submitVideo = (values) => {
        console.log(values);
    };

    return (
        <AppLayout
            title={"Pet Owner"}
            layoutActions={
                <TableBtn
                    label={"Upload Videos"}
                    icon={<UploadVideoIcon />}
                    onClick={() => setShowAddDrawer(true)}
                />
            }
        >
            <PetOwnersDetailTable />
            <SideDrawer title={"Marking Pet as Deceased"} open={showAddDrawer} onClose={() => setShowAddDrawer(false)}>
                <PetDeceasedForm onCancel={() => setShowAddDrawer(false)} />
            </SideDrawer>
        </AppLayout>
    );
}
