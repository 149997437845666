import React from "react";
import AppLayout from "../components/layouts/AppLayout/AppLayout";
import VSPTable from "../features/vsps/VSPTable";
import TableBtn from "../components/ui/TableBtn/TableBtn";
import { useNavigate } from "react-router-dom";

export default function VetrinaryServiceProviders() {
  const navigate = useNavigate();
  const navigateToRequests = () => {
    navigate("/vsp-management/requests");
  };

  return (
    <AppLayout
      title={"VSP Management"}
      layoutActions={
        <TableBtn label={"Requests"} onClick={navigateToRequests} />
      }
    >
      <VSPTable />
    </AppLayout>
  );
  // return (
  //   <AppLayout title={"VSP Management"}>
  //     <VSPTable />
  //   </AppLayout>
  // )
}
