import React from "react";
import AppLayout from "../components/layouts/AppLayout/AppLayout";
import PetVisitsTable from "../components/tables/PetVisitsTable/PetVisitsTable";

export default function PetVisits() {
  return (
    <AppLayout title={"Pet Visits"}>
      <PetVisitsTable />
    </AppLayout>
  );
}
