// VSPSingleRequest.jsx
import React from "react";
import { Form, Row, Col } from "antd";
import "./VSPSingleRequest.scss";

const VSPSingleRequest = ({ data }) => {
  if (!data) {
    return null; // or return a loading indicator, error message, etc.
  }
  return (
    <div className="vsp-single-detail">
      <Form layout="vertical">
        <FormSection title="CONTACT DETAILS">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="First Name">
                <input
                  disabled
                  className="disabled-input"
                  value={data.firstName}
                />
              </Form.Item>
              <Form.Item label="Phone Number">
                <input disabled className="disabled-input" value={data.phone} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Last Name">
                <input
                  disabled
                  className="disabled-input"
                  value={data.lastName}
                />
              </Form.Item>
              <Form.Item label="Email">
                <input disabled className="disabled-input" value={data.email} />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>

        <FormSection title="PROFESSIONAL DETAILS">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="DUNS Number">
                <input
                  disabled
                  className="disabled-input"
                  value={data.DUNSNumber}
                />
              </Form.Item>
              <Form.Item label="Years of Practice">
                <input
                  disabled
                  className="disabled-input"
                  value={data.practiceYears}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="EIN Number">
                <input
                  disabled
                  className="disabled-input"
                  value={data.EINNumber}
                />
              </Form.Item>
              <Form.Item label="Primary DVM License Number">
                <input
                  disabled
                  className="disabled-input"
                  value={data.DVMLicenceNumber}
                />
              </Form.Item>
              <Form.Item label="State Issued">
                <input
                  disabled
                  className="disabled-input"
                  value={data.stateIssued}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Veterinary Management System">
            <input
              disabled
              className="disabled-input"
              value={data.veterinaryManagementSystem}
            />
          </Form.Item>
          <Form.Item label="Upload Documents">
            {data.credentials &&
              data.credentials.map((url, index) => (
                <a key={index} href={url} download={`Document_${index + 1}`}>
                  Document_{index + 1}
                </a>
              ))}
          </Form.Item>
        </FormSection>

        <FormSection title="CLINIC DETAILS">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Clinic Name">
                <input
                  disabled
                  className="disabled-input"
                  value={data.clinicName}
                />
              </Form.Item>
              <Form.Item label="Type of Clinic">
                <input
                  disabled
                  className="disabled-input"
                  value={data.typeOfClinic}
                />
              </Form.Item>
              <Form.Item label="Main Contact Name">
                <input
                  disabled
                  className="disabled-input"
                  value={data.practiceManager}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Primary Clinic Address">
                <input
                  disabled
                  className="disabled-input"
                  value={data.primaryAddress}
                />
              </Form.Item>
              <Form.Item label="Main Clinic Phone Number">
                <input
                  disabled
                  className="disabled-input"
                  value={data.clinicNumber}
                />
              </Form.Item>
              <Form.Item label="Type of Practice">
                <input
                  disabled
                  className="disabled-input"
                  value={data.vetinaryType}
                />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
      </Form>
    </div>
  );
};

const FormSection = ({ title, children }) => {
  return (
    <div className="form-section">
      <h1>{title}</h1>
      {children}
    </div>
  );
};

export default VSPSingleRequest;
