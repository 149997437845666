import { Button, Form, Input, Space, Upload } from "antd";
import React, { useState } from "react";
import imageplaceholder from "../../../assets/images/image-placeholder.svg";
import "./ContentAddForm.scss";
import ButtonGroup from "antd/es/button/button-group";
import TableBtn from "../../../components/ui/TableBtn/TableBtn";
import { useAddVideoMutation } from "../bcpContentApi";

const UPLOAD_URL = `${process.env.REACT_APP_API_SERVER}/file-upload`;

const UploadImagePreview = ({ file }) => {
  return (
    <div className="upload-image-preview">
      <img
        src={file || imageplaceholder}
        style={{ width: "300px", height: "180px" }}
      />
      <div className="size-placeholder">Upload Image (300x300)</div>
    </div>
  );
};

export default function ContentAddForm({ onCancel }) {
  const [addVideo] = useAddVideoMutation();
  const [imageUrl, setImageUrl] = useState(null);

  const submitVideo = (values) => {
    const record = {
      ...values,
      imageUrl: imageUrl,
    };
    addVideo(record);
    onCancel(true);
  };

  const handleUploadContent = (info) => {
    if (info.file.status === "done") {
      const { data } = info.file.response;
      setImageUrl(data.location);
    }
  };

  return (
    <Form onFinish={submitVideo} layout="vertical">
      {/* <Form.Item name={"imageUrl"}>
        <div
          className="image-placeholder"
          style={{
            textAlign: "center",
          }}
        >
          <Upload
            style={{
              margin: "0px auto",
            }}
            onChange={handleUploadContent}
            action={UPLOAD_URL}
            name="image"
          >
            <UploadImagePreview file={imageUrl} />
          </Upload>
        </div>
      </Form.Item>
      <Form.Item
        label="Video name"
        name={"title"}
        rules={[
          {
            required: true,
            message: "Please enter a title",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="URL"
        name={"videoUrl"}
        rules={[
          {
            required: true,
            message: "Please enter a link to the video",
          },
          {
            type: "url",
            message: "Please enter a valid link to the video",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Space align="end" className="mt-5">
        <TableBtn theme={"default"} label={"Cancel"} onClick={onCancel} />
        <TableBtn theme={"primary"} label={"Add Video"} htmlType={"submit"} />
      </Space> */}
      <h1 className="text-4xl "> Comming Soon ⌛</h1>
    </Form>
  );
}
