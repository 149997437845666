// cancelledSubscriptionData.js

const cancelledSubscriptionData = [
  {
    id: 1,
    name: "Pet 1",
    phone: "Owner 1",
    email: "Address 1, City 1, Country 1",
    action: "Cancelled",
    initiateRefundDate: "2024-04-01",
    refundAmount: 10,
    refundableTax: 5,
  },
  {
    id: 2,
    name: "Pet 2",
    phone: "Owner 2",
    email: "Address 2, City 2, Country 2",
    action: "Cancelled",
    initiateRefundDate: "2024-04-02",
    refundAmount: 20,
    refundableTax: 10,
  },
  {
    id: 3,
    name: "Pet 3",
    phone: "Owner 3",
    email: "Address 3, City 3, Country 3",
    action: "Cancelled",
    initiateRefundDate: "2024-04-03",
    refundAmount: 30,
    refundableTax: 15,
  },
  {
    id: 4,
    name: "Pet 4",
    phone: "Owner 4",
    email: "Address 4, City 4, Country 4",
    action: "Cancelled",
    initiateRefundDate: "2024-04-04",
    refundAmount: 40,
    refundableTax: 20,
  },
  {
    id: 5,
    name: "Pet 5",
    phone: "Owner 5",
    email: "Address 5, City 5, Country 5",
    action: "Cancelled",
    initiateRefundDate: "2024-04-05",
    refundAmount: 50,
    refundableTax: 25,
  },
  {
    id: 6,
    name: "Pet 6",
    phone: "Owner 6",
    email: "Address 6, City 6, Country 6",
    action: "Cancelled",
    initiateRefundDate: "2024-04-06",
    refundAmount: 60,
    refundableTax: 30,
  },
  {
    id: 7,
    name: "Pet 7",
    phone: "Owner 7",
    email: "Address 7, City 7, Country 7",
    action: "Cancelled",
    initiateRefundDate: "2024-04-07",
    refundAmount: 70,
    refundableTax: 35,
  },
  {
    id: 8,
    name: "Pet 8",
    phone: "Owner 8",
    email: "Address 8, City 8, Country 8",
    action: "Cancelled",
    initiateRefundDate: "2024-04-08",
    refundAmount: 80,
    refundableTax: 40,
  },
  {
    id: 9,
    name: "Pet 9",
    phone: "Owner 9",
    email: "Address 9, City 9, Country 9",
    action: "Cancelled",
    initiateRefundDate: "2024-04-09",
    refundAmount: 90,
    refundableTax: 45,
  },
  {
    id: 10,
    name: "Pet 10",
    phone: "Owner 10",
    email: "Address 10, City 10, Country 10",
    action: "Cancelled",
    initiateRefundDate: "2024-04-10",
    refundAmount: 100,
    refundableTax: 50,
  },
  {
    id: 11,
    name: "Pet 11",
    phone: "Owner 11",
    email: "Address 11, City 11, Country 11",
    action: "Cancelled",
    initiateRefundDate: "2024-04-11",
    refundAmount: 110,
    refundableTax: 55,
  },
  {
    id: 12,
    name: "Pet 12",
    phone: "Owner 12",
    email: "Address 12, City 12, Country 12",
    action: "Cancelled",
    initiateRefundDate: "2024-04-12",
    refundAmount: 120,
    refundableTax: 60,
  },
  {
    id: 13,
    name: "Pet 13",
    phone: "Owner 13",
    email: "Address 13, City 13, Country 13",
    action: "Cancelled",
    initiateRefundDate: "2024-04-13",
    refundAmount: 130,
    refundableTax: 65,
  },
  {
    id: 14,
    name: "Pet 14",
    phone: "Owner 14",
    email: "Address 14, City 14, Country 14",
    action: "Cancelled",
    initiateRefundDate: "2024-04-14",
    refundAmount: 140,
    refundableTax: 70,
  },
  {
    id: 15,
    name: "Pet 15",
    phone: "Owner 15",
    email: "Address 15, City 15, Country 15",
    action: "Cancelled",
    initiateRefundDate: "2024-04-15",
    refundAmount: 150,
    refundableTax: 75,
  },
  // Add more data as needed
];

export default cancelledSubscriptionData;
