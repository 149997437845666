import "./App.scss";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import store from "./store";
import { useEffect } from "react";
import { app_platform } from "./config";
import { getMessaging, getToken } from "firebase/messaging";
import { messaging } from "./firebase";

function App() {
  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      // generate token
      const token = await getToken(messaging, {
        vapidKey:
          "BKd7VXLiA49XpJstsuL56OLugNM7Sqbv2Sxl0bjSpmoGoHh331BB11MtIxBxKK0VMqiKKhgaLo9E5QPw7CF6D2Q",
      });
      sessionStorage.removeItem("notificationPermission");

      console.log("Token Gen:", token);
    } else if (permission === "denied") {
      let notificationPermission = sessionStorage.getItem(
        "notificationPermission"
      );

      if (!notificationPermission) {
        // alert("You are denied to receive push notifications");
        sessionStorage.setItem("notificationPermission", permission);
      }
    }
  }
  useEffect(() => {
    requestPermission();
  }, []);
  useEffect(() => {
    app_platform === "vsp" && (document.title = "VSP Admin Panel");
    app_platform === "bcp" && (document.title = "BCP Admin Panel");
  }, []);

  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Rubik",
            colorPrimary: "#E68F00",
            colorPrimaryText: "#000000",
          },
        }}
      >
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </ConfigProvider>
    </div>
  );
}

export default App;
