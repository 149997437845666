import React from 'react'
import { Col, Row, Form, Input, Button, Divider, Checkbox, Select, Space } from "antd";
import "./VetPracticeForm.scss";
import { Link } from "react-router-dom";
const { Option } = Select;

export default function VetPracticeForm() {
    return (
        <div className="vet-practice-form onboarding-form">
            <div className="logo-wrapper">
                <img src="/logo-carson-bear.png" />
            </div>
            <div className='top-section'>
                <Row justify="left" className="page-header-wrap">
                    <Col span={12}>
                        <h1>Howdy!</h1>
                        <h2>Help us get to know your practice.</h2>
                    </Col>
                </Row>
                <Row justify="left" className="page-header-wrap">
                    <Col span={20}>
                        <div className='lead'>
                            <h3>
                                BearCarson wants to invest in your practice to promote better wellness and nutrition outcomes. Please help us determine how to best pay you.
                            </h3>
                        </div>
                    </Col>
                </Row>

                <div className="form-wrapper">
                    <Row justify="left">
                        <Col span={20}>
                            <div className="form">
                                <Form layout="vertical">
                                    <div className="form-group">
                                        <Form.Item label="Are you Independent Vet or part of a Group Practice" name="name">
                                            <Input size="large" className="form-control" placeholder="Enter your Name Here" />
                                        </Form.Item>
                                    </div>
                                    <div className="form-group">
                                        <Form.Item label="How many DVMs are at clinic?" name="email">
                                            <Input size="large" className="form-control" placeholder="Enter your Email Here" />
                                        </Form.Item>
                                    </div>
                                    <div className="form-group">
                                        <Form.Item label="Do you care for:" name="yearsOfPractice">
                                            <Input size="large" className="form-control" placeholder="Write here" />
                                        </Form.Item>
                                    </div>
                                    <div className="form-group">
                                        <Form.Item
                                            label="Current Volume"
                                            name="dvmLicenseNumber"
                                            className='volume-section'
                                        >
                                            <Input size="large" className="form-control" placeholder="Write here" />
                                            <Input size="large" className="form-control volume-field" placeholder="Write here" />
                                        </Form.Item>
                                    </div>
                                    <div className="form-group">
                                        <Form.Item label="Maximum Volume" className='volume-section' name="stateIssued" >
                                            <Input size="large" className="form-control" placeholder="Write here" />
                                            <Input size="large" className="form-control volume-field" placeholder="Write here" />
                                        </Form.Item>
                                    </div>

                                    <div className="form-group">
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                size="large"
                                                htmlType="submit"
                                                className="login-btn button-section"
                                            >
                                                Next
                                            </Button>
                                        </Form.Item>
                                    </div>
                                    <div className="form-group">
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                size="large"
                                                htmlType="submit"
                                                className="previous-btn button-section"
                                            >
                                                Previous
                                            </Button>
                                        </Form.Item>
                                    </div>
                                    <div className="form-group signup-section">
                                        <p type="text">
                                            Already have an account?
                                            <Link to="/login" className="signup">
                                                {" "}
                                                Log In{" "}
                                            </Link>
                                        </p>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}
