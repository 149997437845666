import { createSlice } from "@reduxjs/toolkit";
import { apiClient } from "./apiClient";
import { notification } from "antd";
import { getUserFromLocalStorage, removeUserFromLocalStorage } from "../utils/localstorage";
import { useNavigate } from "react-router-dom";

const platform = process.env.REACT_APP_PLATFORM;

apiClient.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: `${platform}/login`,
        method: "POST",
        body: credentials,
      }),
      onQueryStarted: async (credentials, { dispatch, queryFulfilled }) => {
        try {
          console.log('started', queryFulfilled);
          const queryResult = await queryFulfilled;
          const { data: response } = queryResult;
          const { data } = response;
          notification.success({
            message: "Login is successful. Welcome back!"
          });
        } catch (error) {
          console.error("Failed to log in with error:", error);
          const errorResponse = error.error;
          const data = errorResponse.data;
          // const { data } = error.error;
          console.log('data', data);
          notification.error({
            message: "Failed to login",
            description: data?.message,
          });
        }
      },
    }),
    signup: builder.mutation({
      query: (credentials) => ({
        url: `${platform}/signup`,
        method: "POST",
        body: credentials,
      }),
      onQueryStarted: async (credentials, { dispatch, queryFulfilled }) => {
        try {
          console.log('started', queryFulfilled);
          const queryResult = await queryFulfilled;
          const { data: response } = queryResult;
          const { data } = response;
        } catch (error) {
          const errorResponse = error.error;
          const data = errorResponse.data;
          // const { data } = error.error;
          console.log('data', data);
          notification.error({
            message: "Failed to create account!",
            description: data?.message,
          });
        }
      },
    }),
    fetchUser: builder.query({
      query: () => `${platform}/account`,
      transformResponse: response => {
        return response.data;
      },
      onQueryStarted: async (credentials, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log('error', error);
          // if(error.error.status === 401) {
          //   if(!(window.location.pathname === '/login' || window.location.pathname === '/signup')) {
          //     notification.error({
          //       message: "Unauthorized",
          //       description: "You are not authorized to access this resource. Please login or signup to continue.",
          //     });
          //     removeUserFromLocalStorage();
          //     window.location.href = '/login';
          //   }
          // }
        }
      },
    }),
  }),
});

const initialState = () => {
  const storedAuthUser = getUserFromLocalStorage();
  if (storedAuthUser) {
    const user = storedAuthUser;
    const token = user['api-token'];
    return { user, token, isAuthenticated: true };
  }
  return { user: null, token: null, isAuthenticated: false };
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState(),
  // actions: {
   

  // },

  reducers: {
    logoutUser: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
    },

  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiClient.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.user;
        state.token = payload.token;
        state.isAuthenticated = true;
      }
    );
    builder.addMatcher(
      apiClient.endpoints.signup.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.user;
        state.token = payload.token;
        state.isAuthenticated = true;
      }
    );
    builder.addMatcher(
      apiClient.endpoints.fetchUser.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
        state.isAuthenticated = true;
      }
    );
  },
});
export default authSlice.reducer;
export const { logoutUser } = authSlice.actions;

export const { useLoginMutation, useLogoutMutation, useFetchUserQuery, useSignupMutation } = apiClient;