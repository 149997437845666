import { Form, Input, Modal, message } from "antd";

const AddClinicModal = ({ isVisible, handleCancel, reset }) => {
  const [form] = Form.useForm();
  const baseUrl = process.env.REACT_APP_API_SERVER;

  const handleAdd = () => {
    form
      .validateFields()
      .then((values) => {
        const requestData = {
          companyName: values.clinicName,
          tradeStyle: values.clinicName,
        };

        fetch(`${baseUrl}/clinics/clinic-suggestion/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.message === "Execution Successful.") {
              message.success("Clinic added successfully");
              reset();
              handleCancel();
            } else {
              message.error(data.message);
            }
          });
      })
      .catch((error) => {
        console.error("Validation failed:", error);
        message.error("Please enter the clinic name.");
      });
  };

  return (
    <Modal
      title="Add new clinic"
      visible={isVisible}
      onOk={handleAdd}
      onCancel={handleCancel}
      okText="Add"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Clinic Name"
          name="clinicName"
          rules={[{ required: true, message: "Please enter the clinic name!" }]}
        >
          <Input placeholder="Enter clinic name" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddClinicModal;
