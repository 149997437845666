import React from 'react'
import AppLayout from '../components/layouts/AppLayout/AppLayout'
import WellPlanTable from '../components/tables/WellnessPlanTable/WellnessPlanTable'
import BCPNutritionistTable from '../features/bcp-nutritionists/BCPNutritionistTable'

export default function BCPNutritionists() {
  return (
    <AppLayout title={"BCP Nutritionists"}>
      <BCPNutritionistTable />
    </AppLayout>
  )
}
