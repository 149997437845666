import React, { useState } from "react";
import {
  Button,
  DatePicker,
  Input,
  List,
  Pagination,
  Skeleton,
  Space,
  Table,
  Form,
  Row,
  Col,
  Tag,
} from "antd";
import { useFetchInvitedPetOwnersQuery } from "./invitedPetOwnerApi";
import {
  ClockCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import "./PetOwnersTable.scss";
import FilterBy from "../../components/ui/FilterBy/FilterBy";
import SearchBar from "../../components/ui/SearchBar/SearchBar";
import ApplicationStatus from "../../components/ui/ApplicationStatus/ApplicationStatus";
import TableDropdown from "../../components/ui/TableDropdown/TableDropdown";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useFetchVetProfileQuery } from "../../api/onboarding";

const ColumnWrapper = Table.Column;

const tableOptions = [
  {
    label: "View Details",
    key: "view-details",
  },
];
const timezoneOffset = new Date().getTimezoneOffset();
console.log("timezoneOffset", timezoneOffset);
const CustomStatusTag = ({ status }) => {
  switch (status) {
    case 1:
      return (
        <Tag icon={<ClockCircleOutlined />} color="#794E00">
          Invited
        </Tag>
      );
    case 2:
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          Joined
        </Tag>
      );
    case 3:
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          Unavailable
        </Tag>
      );
    default:
      return <Tag>Unknown</Tag>;
  }
};

const Column = ({ title, dataIndex, render, ...restProps }) => {
  return (
    <ColumnWrapper
      title={title}
      dataIndex={dataIndex}
      render={(text, record) => {
        if (render) {
          return render(text, record);
        }
        if (dataIndex === "invitationStatus") {
          return <CustomStatusTag status={text} />;
        }
        return text;
      }}
      {...restProps}
    />
  );
};

const PetOwnersInvitedTable = () => {
  const [statusFilter, setStatusFilter] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [dateFilter, setDateFilter] = React.useState({
    start: null,
    end: null,
  });

  const timezoneOffset = new Date().getTimezoneOffset();

  console.log("dateFilter ===>", dateFilter);

  const options = {};
  if (statusFilter) {
    options.isEnabled = statusFilter === "enabled";
  }
  if (searchQuery) {
    options.search = searchQuery;
  }
  if (page) {
    options.page = page;
  }
  if (dateFilter.start) {
    options.startDate = dateFilter.start;
  }
  if (dateFilter.end) {
    options.endDate = dateFilter.end;
  }
  if (timezoneOffset) {
    options.timezoneOffset = timezoneOffset;
  }

  const { data, isFetching } = useFetchInvitedPetOwnersQuery(options);
  const { items: petOwners, total } = data || {};

  const handleFilterChange = (value) => {
    setStatusFilter(value);
  };

  const handleSearchUpdated = (value) => {
    setPage(1);
    console.log("handleSearchUpdated =====> ", value);
    setSearchQuery(value);
  };

  const handleClick = async (e, id) => {
    navigate(`/pet-owners/${id}`);
  };

  const disabledStartDate = (current) => {
    // Disable dates after today
    return current && current > moment().endOf("day");
  };
  const disabledEndDate = (current) => {
    // Can not select days before the start date
    return current && current < moment(dateFilter.start);
  };

  return (
    <div className="page-wrapper">
      <div className="filter-section mb-5">
        <div className="d-md-flex justify-content-between align-items-center">
          <div className="start-end-date-filter">
            <Form layout="vertical" className="w-100">
              <Row>
                <div
                  xs={24}
                  style={{
                    display: "flex",
                    gap: "1rem",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                  }}
                >
                  <Form.Item
                    label="Start Date"
                    className="filter-section mb-md-0"
                  >
                    <DatePicker
                      size="large"
                      disabledDate={disabledStartDate}
                      onChange={(e) => {
                        console.log(e);
                        setDateFilter({
                          ...dateFilter,
                          start: e ? e.format("YYYY-MM-DD") : null,
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="End Date"
                    className="px-md-3 filter-section  mb-md-0"
                  >
                    <DatePicker
                      disabledDate={disabledEndDate}
                      size="large"
                      onChange={(e) => {
                        console.log(e);
                        setDateFilter({
                          ...dateFilter,
                          end: e ? e.format("YYYY-MM-DD") : null,
                        });
                      }}
                    />
                  </Form.Item>

                  <div
                    className="justify-content-end "
                    style={{ width: "300px" }}
                  >
                    <SearchBar
                      placeholder="Search Pet Owners"
                      value={searchQuery}
                      onChange={handleSearchUpdated}
                    />
                  </div>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </div>
      <div className="table-wrapper">
        <Table
          dataSource={petOwners}
          rowKey={"id"}
          onChange={(pagination) => setPage(pagination.current)}
          pagination={{
            position: ["bottomCenter"],
            pageSize: 10,
            showSizeChanger: false,
            className: "tb-pagination",
            total: total,
          }}
          rowClassName={"animate__animated animate__fadeIn"}
          // Adding a custom column for Section No.
          columns={[
            {
              title: "Section No.",
              key: "sectionNo",
              render: (text, record, index) => index + 1,
            },
            // Your existing columns...

            {
              title: "Pet Owner Name",
              dataIndex: "petOwnerName",
              key: "petOwnerName",
            },
            {
              title: "Pet Owner Email",
              dataIndex: "petOwnerEmail",
              key: "petOwnerEmail",
            },
            {
              title: "Invited By Vet Name",
              dataIndex: "invitedByVetName",
              key: "invitedByVetName",
            },
            {
              title: "Created At",
              dataIndex: "createdAt",
              key: "createdAt",
              render: (text) => (
                <span>{moment(text).format("YYYY-MM-DD")}</span>
              ),
            },
            {
              title: "Invitation Status",
              dataIndex: "invitationStatus",
              key: "invitationStatus",
              render: (status) => <CustomStatusTag status={status} />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default PetOwnersInvitedTable;
