import { Button, Dropdown } from "antd";
import React from "react";
import { VerticalDotsIcon } from "../../Icons";
import './TableDropdown.scss';

export default function TableDropdown({ items = [], onClick }) {

  const handleClick = ({ key }) => {
    onClick(key);
  };

  return (
    <Dropdown
      menu={{
        items,
        onClick: handleClick,
      }}
    >
      <Button type="ghost" className="table-dropdown">
        <VerticalDotsIcon />
      </Button>
    </Dropdown>
  );
}
