import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDNzsxrNCRhETH9-wWLIKIl3mCY2Jeupk4",
  authDomain: "applied-theme-381913.firebaseapp.com",
  projectId: "applied-theme-381913",
  storageBucket: "applied-theme-381913.appspot.com",
  messagingSenderId: "258418181972",
  appId: "1:258418181972:web:dd35fdd64865c3098d6ac1",
  measurementId: "G-9FDYWTPNME",
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);
