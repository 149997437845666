import React from "react";
import AppLayout from "../components/layouts/AppLayout/AppLayout";

import WellnessPlanTableBcp from "../components/tables/WellnessPlanTable/WellnessPlanTableBcp";

export default function WellnessPlan() {
  return (
    <AppLayout title={"Wellness Payout Report"}>
      <WellnessPlanTableBcp />
    </AppLayout>
  );
}
