import React from 'react'
import './TableBtn.scss'
import classNames from 'classnames'

export default function TableBtn({ icon, label, theme, onClick, htmlType }) {
  return (
    <button className={classNames("table-btn", theme)} onClick={onClick} type={htmlType}>
      {icon && <span className="icon">{icon}</span>}
      <span className="label">{label}</span>
    </button>
  );
}
