import React, { useEffect } from 'react'
import { app_platform } from '../config'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '../utils/selectors';


export default function Home() {
  const platform = app_platform;
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    if(isLoggedIn) {
      if(app_platform === 'vsp'){
        navigate('/vsp/home');
      } else {
        navigate('/dashboard');
      }
    } else {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  return (
    <div>

    </div>
  )
}
