import React, { useState } from "react";
import {
  Button,
  DatePicker,
  Input,
  List,
  Pagination,
  Skeleton,
  Space,
  Table,
  Image,
  Drawer,
  Form,
  Select,
  Row,
  Col,
  Tag,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./PetOwnersDetailTable.scss";
import {
  PetActiveIcon,
  PetDeceasedIcon,
  PetDetailsIcon,
} from "../../components/Icons";
import TableDropdown from "../../components/ui/TableDropdown/TableDropdown";
import SideDrawer from "../../components/ui/SideDrawer/SideDrawer";
import PetOwnerDetailForm from "./PetOwnerDetailForm/PetOwnerDetailForm";
import PetDeceasedForm from "./PetDeceasedForm/PetDeceasedForm";
const { Option } = Select;

const ColumnWrapper = Table.Column;

const tableOptions = [
  {
    label: "View Details",
    key: "view-details",
    icon: <PetDetailsIcon />,
  },
];

const Column = (props) => {
  return (
    <ColumnWrapper
      {...props}
      render={(text) => {
        if (!text) {
          return <span>-</span>;
        }
        return text;
      }}
    />
  );
};

const PetOwnersDetailTable = ({ data }) => {
  const [petDetails, setPetDetails] = useState({
    active: false,
    data: null,
  });
  const [deceasedPet, setDeceasedPet] = useState({
    active: false,
    data: null,
  });

  const handleClick = async (e, data) => {
    switch (e) {
      case "view-details":
        console.log("view-details");
        setPetDetails({
          active: true,
          data: data,
        });
        break;
      default:
        break;
    }
  };

  console.log(" Pet Owners ===> ", data);

  return (
    <div className="pet-owner-detail">
      <div className="top-section"></div>
      <div className="form-section">
        <div className="form-container">
          {/* <Row span={10}>
            <Col>
              <Image
                style={{ minWidth: "450px" }}
                src={data?.profilePicURL || "http://placehold.it/400x250"}
              />
            </Col>
          </Row> */}

          <div className="ImageContainer">
            <Image src={data?.profilePicURL || "http://placehold.it/400x250"} />
          </div>

          {/* <Row span={14}>
            <Col span = {24} className="pt-5 pt-md-0">
              <Row gutter={[30, 8]} span={24} xs={12} md={10} wrap>
                <Col span={24} xs={12} md={10}>
                  <span className="filterby-label">Pet Owner’s Name</span>
                  <Input
                    size="large"
                    style={{ minWidth: "150px" }}
                    placeholder="Anna"
                    value={data?.firstName}
                    readOnly
                  />
                </Col>
                <Col span={24} xs={12} md={10}>
                  <span className="filterby-label">Number of Pets</span>
                  <Input
                    size="large"
                    style={{ minWidth: "150px" }}
                    placeholder="5"
                    value={data?.pets.length || 0}
                    readOnly
                  />
                </Col>
                <Col span={12}>
                  <span className="filterby-label">Email</span>
                  <Input
                    size="large"
                    style={{ minWidth: "150px" }}
                    placeholder="5"
                    value={data?.email}
                    readOnly
                  />
                </Col>
                <Col span={12}>
                  <span className="filterby-label">Phone Number</span>
                  <Input
                    size="large"
                    style={{ minWidth: "150px" }}
                    value={data?.phone}
                    readOnly
                  />
                </Col>
                <Col span={24}>
                  <span className="filterby-label">Subscription</span>
                  <Input
                    size="large"
                    style={{ minWidth: "150px" }}
                    value={data?.planSize || "Freemium"}
                    readOnly
                  />
                </Col>
              </Row>
            </Col>
          </Row> */}
          <div className="leftContainer">
            <div className="formGroup">
              <div className="form-input">
                <span className="filterby-label">Pet Owner’s Name</span>
                <Input
                  size="large"
                  style={{ minWidth: "150px" }}
                  placeholder="Anna"
                  value={data?.firstName}
                  readOnly
                />
              </div>
              <div className="form-input">
                <span className="filterby-label">Number of Pets</span>
                <Input
                  size="large"
                  style={{ minWidth: "150px" }}
                  placeholder="5"
                  value={data?.pets.length || 0}
                  readOnly
                />
              </div>
            </div>
            <div className="formGroup">
              <div className="form-input">
                <span className="filterby-label">Email</span>
                <Input
                  size="large"
                  style={{ minWidth: "150px" }}
                  placeholder="5"
                  value={data?.email}
                  readOnly
                />
              </div>
              <div className="form-input">
                <span className="filterby-label">Phone Number</span>
                <Input
                  size="large"
                  style={{ minWidth: "150px" }}
                  value={data?.phone}
                  readOnly
                />
              </div>
            </div>
            {/* <div className="formGroup">
              <div className="form-input">
                <span className="filterby-label">Subscription</span>
                <Input
                  size="large"
                  style={{ minWidth: "150px" }}
                  value={data?.planSize || "Trial"}
                  readOnly
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Table
        rowKey={"id"}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 20,
          showSizeChanger: false,
          className: "tb-pagination",
        }}
        dataSource={data?.pets || []}
      >
        <Column title="Tail No." dataIndex="id" key="id" />
        <Column title="Pet Name" dataIndex="petName" key="petName" />
        <Column
          title="Plan"
          dataIndex="planSize"
          key="planSize"
          render={(text) =>
            text ? text.charAt(0).toUpperCase() + text.slice(1) : ""
          }
        />

        {/* <Column
          title="Deceased"
          dataIndex="isDeceased"
          key="isDeceased"
          render={(text, record) => {
            return (
              <span className="deceased-icon-svg">
                {!record.isDeceased ? <PetActiveIcon /> : <PetDeceasedIcon />}
              </span>
            );
          }}
        /> */}
        <Column
          title="Status"
          dataIndex="petStatus"
          key="petStatus"
          render={(text) => (
            <Tag color={text === "active" ? "green" : "red"}>
              {text === "active" ? "Active" : "Inactive"}
            </Tag>
          )}
        />
        <Column
          title="Action"
          dataIndex="action"
          key="action"
          render={(text, record) => {
            return (
              <TableDropdown
                items={tableOptions}
                onClick={(action) => handleClick(action, record)}
              />
            );
          }}
        />
      </Table>

      {petDetails?.data && (
        <SideDrawer
          title={"Pet Details"}
          open={petDetails.active}
          onClose={() => setPetDetails({ active: false })}
        >
          <PetOwnerDetailForm
            pet={petDetails?.data}
            onCancel={() => setPetDetails({ active: false })}
            onDeceasedClick={() => {
              setDeceasedPet({ active: true, data: petDetails?.data });
              setPetDetails({ active: false });
            }}
          />
        </SideDrawer>
      )}
      <SideDrawer
        title={"Marking Pet as Deceased"}
        open={deceasedPet.active}
        onClose={() => setDeceasedPet({ active: false })}
      >
        <PetDeceasedForm
          pet={deceasedPet?.data}
          onCancel={() => setDeceasedPet({ active: false })}
          data={deceasedPet?.data}
        />
      </SideDrawer>
    </div>
  );
};

export default PetOwnersDetailTable;
