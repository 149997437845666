import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Divider,
  Checkbox,
  Upload,
  Select,
  notification,
  message,
} from "antd";

import "./OnboardingSignupForm.scss";
import { Link, useNavigate } from "react-router-dom";
import { AvatarIcon, EmailIcon, PhoneIcon } from "./FormIcons";
import { saveUserToLocalStorage } from "../../../utils/localstorage";
import { useSignupMutation } from "../../../api/auth";
import { useSelector } from "react-redux";
import { selectUser } from "../../../utils/selectors";
import validateRegex, {
  validateConfirmPassword,
  validateName,
  validatePassword,
  validatePhoneNo,
} from "../../../utils/validateRegex";
import AddClinicModal from "./AddClinicModal/AddClinicModal";

export default function SignupForm() {
  const isLoggedIn = useSelector(selectUser);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [signup] = useSignupMutation();
  const [formState, setFormState] = useState({
    currentStep: 1,
    totalSteps: 3,
    formValues: {},
  });

  const [loading, setLoading] = useState(false);
  // const [checked, setChecked] = useState(false);
  const [passwords, updatePassword] = useState({
    password: "",
    confirmPassword: "",
  });

  const [extraPayload, setExtraPayload] = useState(undefined);
  console.log(extraPayload);

  const [isAddClinicModalVisible, setIsAddClinicModalVisible] = useState(false);

  const [searchedValue, setSearchedValue] = useState("");

  const addCustomOption = (searchValue) => {
    const isOptionExist =
      clinicList?.length > 0 &&
      clinicList.some(
        (option) =>
          option.clinicName.toLowerCase() === searchValue.toLowerCase()
      );

    if (!isOptionExist) {
      setSearchedValue(searchValue);
    }
  };

  const [clinicList, setClinicList] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  const baseUrl = process.env.REACT_APP_API_SERVER;
  const searchTerms = "tradeStyle";

  const [page, setpage] = useState(1);

  const fetchData = () => {
    fetch(
      `${baseUrl}/clinics/clinic-suggestion?selectFields=id,tradeStyle,DUNSNumber,firstName,lastName,addressLine1,state,phone&searchTerms=${searchTerms}&search=${searchValue.toLowerCase()}&page=${page}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (page === 1) {
          setClinicList(data.data);
        } else {
          if (data.data.length !== 0) {
            if (Number.isInteger(data.data)) {
              setClinicList([...clinicList, data.data]);
            }
          } else {
            message.error("You have reached the end");
          }
        }
      });
  };

  useEffect(() => {
    if (searchValue.length > 2) {
      setTimeout(() => fetchData(), 1000);
    }

    if (clinicList?.length === 0) {
      setClinicList([]);
    }
  }, [searchValue]);

  const handleSearch = (value) => {
    if (clinicList?.length === 0) {
      addCustomOption(value);
    }
    setSearchValue(value);
  };

  const addinitialValues = (clinicName) => {
    const selectedClinic = clinicList.find(
      (clinic) => clinic.tradeStyle === clinicName
    );
    console.log(selectedClinic);
    if (selectedClinic !== undefined) {
      localStorage.setItem("autoFill", JSON.stringify(selectedClinic));
      localStorage.setItem("id", JSON.stringify(selectedClinic.id));
      setExtraPayload(selectedClinic.tradeStyle);
    }
  };

  console.log(formState);

  console.log("passwords ====> ", passwords);

  const handleSubmit = (values) => {
    console.log(values);
    if (values.password !== values.confirm_password) {
      notification.error({
        message: "Password mismatch!",
        description: "Password and Confirm Password should be same",
      });
      return;
    }
    // if (!checked) {
    //   notification.error({
    //     // message: "Password mismatch!",
    //     description:
    //       "Please check  privacy policy  and the veterinary service provider agreement",
    //   });
    //   return;
    // }
    signup({
      firstName: values.first_name,
      lastName: values.last_name,
      email: values.email.toLowerCase(),
      password: values.password,
      phone: values.mobile,
      clinicName:
        extraPayload === "Add custom" ? "general" : extraPayload || "general",
      // ...extraPayload,
    })
      .then((res) => {
        console.log("res", res);
        const { data: response } = res.data;
        const token = response["api-token"];
        saveUserToLocalStorage(response, token);
        console.log("redirecting to onboarding", response, token);
        setTimeout(() => {
          window.location.href = "/vsp/onboarding";
          //store selected opetions data in local storage also cleare local storage latter on
        }, 250);
      })
      .catch((err) => {
        console.log(err);
        notification.error({
          message: "Error",
          description: "Something went wrong",
        });
      });
  };

  const handleField = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "password":
        updatePassword({
          ...passwords,
          password: value,
        });

        break;
      case "confirmPassword":
        updatePassword({
          ...passwords,
          confirmPassword: value,
        });

        break;

      default:
        break;
    }
  };

  const addNewClinic = () => {
    setIsAddClinicModalVisible(true);
  };

  const handlePopupScroll = (e) => {
    const target = e.target;
    const scrollTopclientHeight = target.scrollTop + target.clientHeight + 1;

    // Check if the user has scrolled to the bottom of the dropdown
    if (Math.floor(scrollTopclientHeight) === target.scrollHeight) {
      setpage((state) => state + 1);
      fetchData();
    }
  };

  // const addNewFields = () => {
  //   // Simulating an API call to fetch more clinic data
  //   setTimeout(() => {
  //     const moreClinicData = /* Your logic to fetch more clinic data */;
  //     setClinicList((prevClinicList) => [...prevClinicList, ...moreClinicData]);
  //     setLoading(false);
  //   }, 1000);
  // };

  return (
    <>
      <div className="signup-form auth-form" style={{ overflow: "auto" }}>
        <div className="logo-wrapper">
          <img className="mx-16" src="/logo-carson-bear.png" />
        </div>
        <Row justify="center" className="page-header-wrap mt-10 mx-auto">
          <Col span={22}>
            <h1>Howdy!</h1>
            <h2 className="fw-light">Let's get to know you.</h2>
          </Col>
        </Row>
        <div className="form-wrapper">
          <Row justify="center">
            <Col span={12}>
              <div className="form">
                <Form
                  layout="vertical"
                  onFinish={handleSubmit}
                  style={{ backgroundClip: "#F9F9F9" }}
                  form={form}
                  initialValues={formState.formValues}
                >
                  {loading && (
                    <div className="signupform-loading-overlay">
                      <div className="loading">
                        <div className="loading__icon"></div>
                        <div className="loading__text">Submitting...</div>
                      </div>
                    </div>
                  )}

                  {/* Drop Down */}
                  <div className="form-group">
                    <Form.Item
                      label="Clinic name"
                      name="clinicName"
                      rules={[
                        {
                          required: true,
                          message: "Please select a clinic name",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        size="large"
                        placeholder="Enter clinic name"
                        className="auth-form-select"
                        onChange={addinitialValues}
                        onSearch={handleSearch}
                        value={searchedValue}
                        onPopupScroll={handlePopupScroll}
                      >
                        {clinicList?.length > 0
                          ? clinicList.map((name) => (
                              <Select.Option
                                key={Math.random()}
                                value={name.tradeStyle}
                              >
                                {name.tradeStyle}
                              </Select.Option>
                            ))
                          : searchedValue && (
                              <Select.Option
                                key={searchedValue}
                                value={searchedValue}
                              >
                                <p onClick={addNewClinic}>
                                  {searchedValue} (Add custom)
                                </p>
                              </Select.Option>
                            )}
                      </Select>
                    </Form.Item>
                  </div>

                  {/* First Name */}
                  <div className="form-group">
                    <Form.Item
                      label="First name"
                      name="first_name"
                      rules={[
                        {
                          validator: validateName,
                        },
                        {
                          required: true,
                          message: "Please enter your first name",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        className="form-control"
                        suffix={<AvatarIcon />}
                        placeholder="Enter enter first name here"
                      />
                    </Form.Item>
                  </div>

                  {/* Last Name */}
                  <div>
                    <div className="form-group">
                      <Form.Item
                        label="Last name"
                        name="last_name"
                        rules={[
                          {
                            validator: validateName,
                          },
                          {
                            required: true,
                            message: "Please enter your last name",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="form-control"
                          suffix={<AvatarIcon />}
                          placeholder="Enter your last name here"
                        />
                      </Form.Item>
                    </div>

                    {/* Mobile No */}
                    <div className="form-group">
                      <Form.Item
                        label="Mobile number"
                        name="mobile"
                        rules={[
                          {
                            required: true,
                            validator: validatePhoneNo,
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="form-control"
                          type="number"
                          suffix={<PhoneIcon />}
                          placeholder="Enter your mobile here"
                        />
                      </Form.Item>
                    </div>

                    {/* Email */}
                    <div className="form-group">
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: "Please enter a valid email address",
                          },
                          {
                            required: true,
                            message: "Please enter your email",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="form-control"
                          suffix={<EmailIcon />}
                          placeholder="Enter your Email here"
                        />
                      </Form.Item>
                    </div>

                    {/* Password */}
                    <div className="form-group">
                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            validator: validatePassword,
                          },
                        ]}
                      >
                        <Input.Password
                          size="large"
                          value={passwords?.password}
                          name={"password"}
                          onChange={handleField}
                          className="form-control"
                          suffix={<EmailIcon />}
                          placeholder="Enter your password here"
                        />
                      </Form.Item>
                    </div>

                    {/* Confirm Password */}
                    <div className="form-group">
                      <Form.Item
                        label="Confirm Password"
                        name="confirm_password"
                        rules={[
                          {
                            validator: (val, value) =>
                              validateConfirmPassword(
                                val,
                                value,
                                passwords?.password
                              ),
                          },
                        ]}
                      >
                        <Input.Password
                          size="large"
                          value={passwords?.confirmPassword}
                          name={"confirmPassword"}
                          onChange={handleField}
                          className="form-control"
                          suffix={<EmailIcon />}
                          placeholder="Enter your password here"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    textAlign: "left",
                    alignItems: "flex-start ",
                    marginTop: "1rem",
                  }}
                >
                  <Checkbox onClick={handleChecked}>
                    {" "}
                    I have read, understood, and agreed to the{" "}
                    <a
                      href="https://carsonandbearpets.com/privacy-policy/"
                      target="_Blank"
                      alt=" Privacy Policy"
                    >
                      {" "}
                      Privacy Policy{" "}
                    </a>
                    and the Veterinary Service{" "}
                    <a
                      href="https://carsonandbearpets.com/agreement/"
                      target="_Blank"
                      alt=" Provider Agreement"
                    >
                      {" "}
                      Provider Agreement{" "}
                    </a>
                    .
                  </Checkbox>
                </div> */}

                  <div className="form-group button-section">
                    <Button
                      type="primary"
                      size="large"
                      className="login-btn"
                      htmlType="submit"
                      loading={loading}
                    >
                      Sign Up
                    </Button>
                  </div>

                  <div className="form-group signup-section">
                    <p type="text">
                      Already have an account?
                      <Link to="/login" className="signup">
                        Log In
                      </Link>
                    </p>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <AddClinicModal
        isVisible={isAddClinicModalVisible}
        handleCancel={() => {
          setIsAddClinicModalVisible(false);
          form.resetFields(["clinicName"]);
        }}
        reset={() => form.resetFields(["clinicName"])}
      />
    </>
  );
}
