import React from 'react'
import AppLayout from '../components/layouts/AppLayout/AppLayout'
import BCPNutritionistTable from '../features/bcp-nutritionist/BCPNutritionistTable'

export default function BCPNutritionist() {
  return (
    <AppLayout title={"BCP Nutritionist"}>
      <BCPNutritionistTable />
    </AppLayout>
  )
}
