import { app_platform } from '../config';

const useAppPlatform = ({ vsp, bcp } = { vsp: null, bcp: null }) => {
  const platform = app_platform;

  if (platform === 'vsp') {
    return vsp;
  }

  if (platform === 'bcp') {
    return bcp;
  }
};

export default useAppPlatform;
