import { apiClient } from "../../../api/apiClient";

const TAG = "WellnessDollars";
const platform = process.env.REACT_APP_PLATFORM;
const baseUrl = `clinics/wellness-dollars`;

export const wellnessDollarsApi = apiClient.injectEndpoints({
  endpoints: (builder) => ({
    fetchWellnessDollars: builder.query({
      query: (id) => ({
        url: `${baseUrl}/${id}`,
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: [TAG],
    }),
    // New endpoint for searching
    searchWellnessDollars: builder.query({
      query: (id, searchTerm = "") => ({
        url: `${baseUrl}/${id}?search=${searchTerm}`,
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: [TAG],
    }),
    updateWellnessDollars: builder.mutation({
      query: (data) => ({
        url: `${baseUrl}/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [TAG],
    }),
  }),
});

export const {
  useFetchWellnessDollarsQuery,
  useUpdateWellnessDollarsMutation,
  useSearchWellnessDollarsQuery, // Include the new hook for searching
} = wellnessDollarsApi;
