import React, { useState } from "react";
import { Button, Skeleton, Table } from "antd";
import {
  useFetchPetOwnersQuery,
  useUpdatePetOwnerMutation,
} from "../pet-owners/petOwnersApi";
import FilterBy from "../../components/ui/FilterBy/FilterBy";
import SearchBar from "../../components/ui/SearchBar/SearchBar";

const UsersList = () => {
  const { data: response, isFetching } = useFetchPetOwnersQuery();
  const [updatePetOwner] = useUpdatePetOwnerMutation();
  const [searchQuery, setSearchQuery] = useState("");

  if (isFetching) {
    return <Skeleton active />;
  }

  const handleEnable = async (id) => {
    try {
      const response = await updatePetOwner({ id, name: "John Doe" }).unwrap();
      console.log("User updated:", response);
    } catch (error) {
      console.error("Failed to update user:", error);
    }
  };

  const handleSearchUpdated = (value) => {
    setSearchQuery(value);
  };

  const filteredUsers = response.items.filter((user) =>
    `${user.firstName} ${user.lastName}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <div className="filter-section mb-5">
        <div className="d-block d-md-flex justify-content-between align-items-center">
          <div className="single-filter pb-3 pb-md-0"></div>
          <SearchBar
            placeholder="Search Pet owners here"
            value={searchQuery}
            onChange={handleSearchUpdated}
          />
        </div>
      </div>
      <Table dataSource={filteredUsers} rowKey={"id"}>
        <Table.Column
          title="First Name"
          dataIndex="firstName"
          key="firstName"
        />
        <Table.Column title="Last Name" dataIndex="lastName" key="lastName" />
        <Table.Column title="Email" dataIndex="email" key="email" />
        <Table.Column title="Pet Count" dataIndex="petCount" key="petCount" />
      </Table>
    </div>
  );
};

export default UsersList;
