import React from "react";
import {
    Button,
    DatePicker,
    Input,
    List,
    Pagination,
    Skeleton,
    Space,
    Table,
} from "antd";
import "./BCPNutritionistTable.scss";
import FilterBy from "../../components/ui/FilterBy/FilterBy";
import { SearchIcon } from "../../components/Icons";
import SearchBar from "../../components/ui/SearchBar/SearchBar";
const ColumnWrapper = Table.Column;

const Column = (props) => {
    return (
        <ColumnWrapper
            {...props}
            render={(text) => {
                if (!text) {
                    return <span>-</span>;
                }
                return text;
            }}
        />
    );
};

const BCPNutritionistTable = () => {
    return (
        <div className="page-wrapper">
            <div className="filter-section mb-5">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="single-filter">
                        <FilterBy placeholder={"VSP"} />
                    </div>
                    <SearchBar placeholder="Search pets & pet owners here" />
                </div>
            </div>
            <Table
                rowKey={"id"}
                pagination={{
                    position: ["bottomCenter"],
                    pageSize: 20,
                    showSizeChanger: false,
                    className: "tb-pagination",
                }}
            >
                <Column title="Tail no." dataIndex="id" key="id" />
                <Column title="Pet Owner Name" dataIndex="petOwnerName" key="petOwnerName" />
                <Column title="No. of Pets" dataIndex="noOfPets" key="noOfPets" />
                <Column title="Phone Number" dataIndex="phoneNumber" key="phoneNumber" />
                <Column title="Subscription Plan" dataIndex="subscriptionPlan" key="subscriptionPlan" />
                <Column title="Revise Plans" dataIndex="revisePlans" key="revisePlans" />
                <Column title="Reffered Vet" dataIndex="refferedVet" key="refferedVet" />
                <Column title="Appointment Dates" dataIndex="appointmentDates" key="appointmentDates" />
                <Column title="Action" dataIndex="action" key="action" />
            </Table>
        </div>
    );
};

export default BCPNutritionistTable;
