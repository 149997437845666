import { apiClient } from "./apiClient";

const TAG = "PetOwners";
const baseUrl = "/vsp/profile";

export const onboardingApi = apiClient.injectEndpoints({
  endpoints: (builder) => ({
    fetchVetProfile: builder.query({
      query: (params) => ({
        url: baseUrl,
        params,
      }),
      transformResponse: (response) => {
        return response.data;
      },
    }),
    updateVetProfile: builder.mutation({
      query: (data) => ({
        url: baseUrl,
        method: "PUT",
        body: data,
      }),
    }),
    submitVetProfile: builder.mutation({
      query: () => ({
        url: baseUrl + "/submit-profile",
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useFetchVetProfileQuery,
  useUpdateVetProfileMutation,
  useSubmitVetProfileMutation,
} = onboardingApi;
