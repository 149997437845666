import React from "react";
import AppLayout from "../components/layouts/AppLayout/AppLayout";
import PetOwnersDetailTable from "../features/pet-owners-detail/PetOwnersDetailTable";
import TableBtn from "../components/ui/TableBtn/TableBtn";
import { UploadVideoIcon } from "../components/Icons";
import SideDrawer from "../components/ui/SideDrawer/SideDrawer";
import PetOwnerDetailForm from "../features/pet-owners-detail/PetOwnerDetailForm/PetOwnerDetailForm";
import {
  useFetchPetOwnersQuery,
  useGetPetOwnerQuery,
} from "../features/pet-owners/petOwnersApi";
import { useLocation, useParams } from "react-router-dom";
import { useFetchVetProfileQuery } from "../api/onboarding";

export default function PetOwnerDetail() {
  const params = useParams();

  const { data: vetProfile } = useFetchVetProfileQuery();

  const { data: petOwnerData, isFetching } = useGetPetOwnerQuery(
    `${params.id}?DNUSNumner=${vetProfile?.DUNSNumber}`
  );

  return (
    <AppLayout title={"Pet Owner"}>
      <PetOwnersDetailTable data={petOwnerData} />
    </AppLayout>
  );
}
