import React from "react";
import AppLayout from "../components/layouts/AppLayout/AppLayout";
import VSPSingleRequestForm from "../features/vsp-single-request/VSPSingleRequest";
import {
  useApproveRejectRequestMutation,
  useGetVSPSingleRequestQuery,
} from "../features/vsp-requests/vspRequestApi";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import TableBtn from "../components/ui/TableBtn/TableBtn";
import { Space, notification } from "antd";

export default function VSPSingleRequest() {
  const params = useParams();
  const params2 = new URLSearchParams(window.location.search);
  const viewOnlyParam = params2.get("viewOnly");

  const { data: vspRequestData } = useGetVSPSingleRequestQuery(params.id);
  const [approveRejectRequest] = useApproveRejectRequestMutation();
  const navigate = useNavigate();

  const approveRequest = () => {
    approveRejectRequest({ id: params.id, action: "approve" });
    notification.success({
      message: "VSP Approved",
      description: `${vspRequestData.basicInfo.name}'s account has been approved successfully`,
    });
    navigate("/vsp-management/requests");
  };

  const rejectRequest = () => {
    approveRejectRequest({ id: params.id, action: "reject" });
    notification.error({
      message: "VSP Rejected",
      description: `${vspRequestData.vetName}'s account has been rejected`,
    });
    navigate("/vsp-management/requests");
  };

  if (viewOnlyParam) {
    return (
      <AppLayout title={"My Profile"}>
        <VSPSingleRequestForm data={vspRequestData} />
      </AppLayout>
    );
  }

  return (
    <AppLayout
      layoutActions={
        <Space>
          <TableBtn
            className="btn btn-primary"
            label="Approve"
            onClick={approveRequest}
          />
          <TableBtn
            className="btn btn-primary"
            label="Reject"
            theme="default"
            onClick={rejectRequest}
          />
        </Space>
      }
      title={"Request View Details"}
    >
      <VSPSingleRequestForm data={vspRequestData} />
    </AppLayout>
  );
}
