import React from 'react'
import { Col, Row, Form, Input, Button, Divider, Checkbox, Select } from "antd";
import "./ClinicInfoForm.scss";
import { Link } from "react-router-dom";
const { Option } = Select;

const prefixSelector = (
    <Form.Item name="prefix" noStyle>
        <Select size="large">
            <Option value="86">+86</Option>
            <Option value="87">+87</Option>
        </Select>
    </Form.Item>
);

export default function ClinicInfoForm() {
    return (
        <div className="clinic-info-form onboarding-form">
            <div className="logo-wrapper">
                <img src="/logo-carson-bear.png" />
            </div>
            <Row justify="center" className="page-header-wrap">
                <Col span={10}>
                    <h1>Howdy!</h1>
                    <h2>Let's get to know you.</h2>
                </Col>
            </Row>
            <div className="form-wrapper">
                <Row justify="center">
                    <Col span={10}>
                        <div className="form">
                            <Form layout="vertical">
                                <div className="form-group">
                                    <Form.Item label="Additional address" name="additionalAddress">
                                        <Input size="large" className="form-control" placeholder="Enter your address here" />
                                    </Form.Item>
                                </div>
                                <div className="form-group">
                                    <Form.Item label="Type of clinic" name="typeOfClinic">
                                        <Select size="large" className="form-control" placeholder="Select from here">
                                            <Option value="demo" >Demo</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="form-group">
                                    <Form.Item
                                        name="clinicnumber "
                                        label="Clinic Number "
                                    >
                                        <Input addonBefore={prefixSelector} size="large" className="form-control" placeholder="Enter your clinic number here" />
                                    </Form.Item>
                                </div>
                                <div className="form-group">
                                    <Form.Item label="Clinic Email" name="clinicEmail">
                                        <Input size="large" className="form-control" placeholder="Enter your Email here" />
                                    </Form.Item>
                                </div>
                                <div className="form-group">
                                    <Form.Item
                                        label="Practice manager"
                                        name="practiceManager"
                                    >
                                        <Select size="large" className="form-control" placeholder="Select from here">
                                            <Option value="demo" >Demo</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="form-group">
                                    <Form.Item label="EIN number" name="einNumber">
                                        <Input size="large" className="form-control" placeholder="Write here" />
                                    </Form.Item>
                                </div>
                                <div className="form-group">
                                    <Form.Item label="Upload Credential" name="uploadCredential">
                                        <Input size="large" className="form-control" placeholder="Upload your credential here" />
                                    </Form.Item>
                                </div>

                                <div className="form-group">
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            size="large"
                                            htmlType="submit"
                                            className="login-btn"
                                        >
                                            Next
                                        </Button>
                                    </Form.Item>
                                </div>
                                <div className="form-group">
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            size="large"
                                            htmlType="submit"
                                            className="previous-btn button-section"
                                        >
                                            Previous
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
